// @ts-check
const isServerSide = typeof window === "undefined";
const isDevelopment = process.env.NODE_ENV === "development";

/** @type {import("next-i18next").UserConfig['i18n']} */
const i18n = {
  locales: ["tr", "en"],
  defaultLocale: "tr",
  localeDetection: false, // disable automatic redirection on the user's preferred locale
};

/** @type {import("next-i18next").UserConfig} */
const next18nextConfig = {
  i18n,
  returnNull: false,
  fallbackLng: "tr",
  localePath: "/public/locale",
  reloadOnPrerender: isDevelopment,
};

if (isServerSide) {
  const path = require("path");

  // this dumb line is needed to copy the locales folder to the output folder
  // it doesn't make any sense but it doesn't work if we read the path from the env!
  // it should be hard-coded
  path.resolve("./public/locales");

  let localePath = "apps/frontend/public/locales";
  if (process.env.LOCALES_DIRECTORY_ROOT) {
    // for standalone target running in Docker
    localePath = process.env.LOCALES_DIRECTORY_ROOT;
  } else if (!!process.env.VERCEL && !process.env.CI) {
    // for Vercel serverless functions (not build-time)
    localePath = "./public/locales";
  }
  next18nextConfig.localePath = path.resolve(localePath);
}

module.exports = next18nextConfig;
