import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export function useNavigationLoading() {
  const [navigationLoading, setNavigationLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handleStart = (...args: any[]) => {
      setNavigationLoading(true);
    };
    const handleStop = () => {
      setNavigationLoading(false);
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleStop);
    router.events.on("routeChangeError", handleStop);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleStop);
      router.events.off("routeChangeError", handleStop);
    };
  }, [router]);

  return [navigationLoading];
}
