import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import { BaseAPI, BaseAuthorizedAPI, BaseNextAPI } from "./apis/config";
import { listenerMiddleware } from "./listenerMiddleware";
import {
  AuthSlice,
  DrawerSlice,
  QuizSlice,
  AddressModalSlice,
  PacketsSlice,
  ProductsSlice,
  FilterPacketsSlice,
  CartSlice,
  ConfirmationModalSlice,
  CustomPacketSlice,
  OrdersSlice,
  TransactionModalSlice,
  CargoTrackSlice,
  CheckoutSlice,
  SnackbarSlice,
  ChangeOrderDateModalSlice,
  EditSubscriptionSlice,
  RefundSlice,
  AnalyticsSlice,
} from "./slices";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["CartSlice", "AuthSlice"],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    AddressModalSlice: AddressModalSlice.reducer,
    AuthSlice: AuthSlice.reducer,
    CartSlice: CartSlice.reducer,
    ConfirmationModalSlice: ConfirmationModalSlice.reducer,
    CustomPacket: CustomPacketSlice.reducer,
    DrawerSlice: DrawerSlice.reducer,
    QuizSlice: QuizSlice.reducer,
    PacketsSlice: PacketsSlice.reducer,
    ProductsSlice: ProductsSlice.reducer,
    FilterPacketSlice: FilterPacketsSlice.reducer,
    TransactionModalSlice: TransactionModalSlice.reducer,
    OrdersSlice: OrdersSlice.reducer,
    CargoTrackSlice: CargoTrackSlice.reducer,
    CheckoutSlice: CheckoutSlice.reducer,
    SnackbarSlice: SnackbarSlice.reducer,
    ChangeOrderDateModalSlice: ChangeOrderDateModalSlice.reducer,
    EditSubscriptionSlice: EditSubscriptionSlice.reducer,
    RefundSlice: RefundSlice.reducer,
    AnalyticsSlice: AnalyticsSlice.reducer,
    // API Reducers
    [BaseAPI.reducerPath]: BaseAPI.reducer,
    [BaseAuthorizedAPI.reducerPath]: BaseAuthorizedAPI.reducer,
    [BaseNextAPI.reducerPath]: BaseNextAPI.reducer,
  })
);

const CONFIRMATION_MODAL_OPEN = "confirmationmodal/open";
const CONFIRMATION_MODAL_CLOSE = "confirmationmodal/close";
const FORGOT_PASSWORD = "mutateForgotPassword/fulfilled";
const RESET_PASSWORD = "mutateResetPassword/fulfilled";

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          CONFIRMATION_MODAL_OPEN,
          CONFIRMATION_MODAL_CLOSE,
          FORGOT_PASSWORD,
          RESET_PASSWORD,
          "confirmationModal/toggleConfirmationOpen",
        ],
        ignoredPaths: ["ConfirmationModalSlice.data"],
      },
    })
      .concat(
        BaseAPI.middleware,
        BaseAuthorizedAPI.middleware,
        BaseNextAPI.middleware
      )
      .prepend(listenerMiddleware.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
