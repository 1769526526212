export function toggleItemInArray<T>(array: T[], item: T) {
  if (array.includes(item)) {
    return array.filter((i) => i !== item);
  }
  return [...array, item];
}

export function combinationWithRepetition<T>(list: T[], length?: number) {
  if (!length) length = list.length; // Length of the combinations
  const data = Array(length); // Used to store state
  const results = []; // Array of results
  (function f(pos, start) {
    // Recursive function
    if (pos === length) {
      // End reached
      results.push(data.slice()); // Add a copy of data to results
      return;
    }
    for (let i = start; i < list.length; ++i) {
      data[pos] = list[i]; // Update data
      f(pos + 1, i); // Call f recursively
    }
  })(0, 0); // Start at index 0
  return results; // Return results
}

export function getRandomElements<T>(array: T[], count: number) {
  // Math.random() - 0.5 returns a number between -0.5 and 0.5
  // So 50% of the time it will be negative and 50% of the time it will be positive
  // We use this trick to randomize the array
  const shuffled = array.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
}
