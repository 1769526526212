import { orderBy, groupBy, entries } from "lodash";

import type { PacketItem } from "src/types/Packet";
import type { ProductSubType } from "src/types/Product";

// info: extract popular packets via usageCount
export const extractPopularPackets = (
  packets: IPacket[],
  packetTypeID: string
) => {
  const extractedPacketByTypeID = packets?.filter(
    (packet) => packet._packetType === packetTypeID
  );

  return orderBy(extractedPacketByTypeID, ["usageCount"]).slice(0, 6);
};

/**
 * This function is used to sorting the packet items by sub type name
 * @param name
 * @returns
 */
const sortNamesBySubTypes = (name: string) => {
  let priority = 1;

  if (name.includes("Super+")) {
    priority = 4;
  } else if (name.includes("Super")) {
    priority = 3;
  } else if (name.includes("Standard")) {
    priority = 2;
  }

  // all tampon types should be sorted on the bottom
  // so we add 10 to their proirit
  if (name.includes("Tampon")) {
    priority += 10;
  }
  if (name.includes("Pantyliner")) {
    priority -= 20;
  }

  return priority;
};

const combineNameAndAmount = (
  name: string,
  amount: number,
  language: "en" | "tr"
) => {
  if (language === "en") {
    return `${name} ${amount}`;
  }

  return `${amount} ${name}`;
};

export const getSubTypePriority = (subType: ProductSubType) =>
  sortNamesBySubTypes(subType.name.en);

export const generatePacketNameBySubtypeAndAmount = (
  subTypesAndAmounts: { _subType: string; amount: number }[],
  subTypes: ProductSubType[],
  language: "en" | "tr"
) => {
  let data = subTypesAndAmounts.map((item) => ({
    ...item,
    subType: subTypes.find((x) => x.id === item._subType),
  }));

  data = orderBy(data, (item) => item?.subType?.priority);

  // generate name for each sub type
  return data.map((item) =>
    combineNameAndAmount(
      item?.subType?.name[language] || "",
      item.amount,
      language
    )
  );
};

/**
 * This function is using for combining names of products in packet.
 * @param packetItems
 * @param subTypes
 * @param language
 * @returns
 */
export const generatePacketNameByProductItems = (
  packetItems: PacketItem[],
  subTypes: ProductSubType[],
  products: IProduct[],
  language: "en" | "tr"
) => {
  const tmp = packetItems.map((item) => {
    const product = products?.find((p) => p._id === item._product);

    return {
      ...item,
      productSize: product?.productSize || 0,
    };
  });

  // group by product sub type
  const tmpGrouped = entries(groupBy(tmp, (item) => item._productSubType));

  const tmpMapped = tmpGrouped.map(([key, value]) => ({
    _subType: key,
    amount: value.reduce(
      (acc, item) => acc + item.productSize * item.quantity,
      0
    ),
  }));

  // calculate total amount of products in each sub types
  return generatePacketNameBySubtypeAndAmount(tmpMapped, subTypes, language);
};

export const createFilterQuery = (
  items: { key: string; values: string[]; valuesNotSelected?: any }[],
  isForPacket?: boolean
) => {
  const MONGO_IN_OPERATOR = "$in";
  const MONGO_NIN_OPERATOR = "$nin";
  const MONGO_AND_OPERATOR = "$and";

  if (isForPacket) {
    return `&filter={"${MONGO_AND_OPERATOR}": ${JSON.stringify(
      items.map(({ key, values, valuesNotSelected }) =>
        valuesNotSelected
          ? {
              [key]: {
                [MONGO_IN_OPERATOR]: values,
                [MONGO_NIN_OPERATOR]: valuesNotSelected,
              },
            }
          : {
              [key]: {
                [MONGO_IN_OPERATOR]: values,
              },
            }
      )
    )}}`;
  }

  return `&filter=${JSON.stringify(
    Object.fromEntries(
      items.map(({ key, values }) => [key, { [MONGO_IN_OPERATOR]: values }])
    )
  )}`;
};
