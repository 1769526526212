import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type {
  DistanceSalesContract,
  OrderObject,
  PrivateSimulateResponseDto,
} from "src/interfaces/API/SimulateDtos";

import type { RootState } from "../store";

type CheckoutState = {
  editedSubscriptions: PrivateSimulateResponseDto["editedSubscriptions"];
  editSubscriptionsCartItems?: PrivateSimulateResponseDto["editSubscriptionsCartItems"];
  newSubscriptions: PrivateSimulateResponseDto["newSubscriptions"];
  postponedOneTimePurchase?: {
    isActive: boolean;
    products: { productPicture: IProduct };
    _region: string;
    _user: string;
  };
  price: Partial<{ total: string; cartItems: string }>;
  distanceSalesContract?: DistanceSalesContract;
  order?: OrderObject;
  promoCode?: string;
  promoCodeError?: ApiI18Response;
  token?: string;
  useAccountBalance?: boolean;
  _card?: string;
};

const initialState: CheckoutState = {
  editedSubscriptions: [],
  newSubscriptions: [],
  price: {},
  distanceSalesContract: undefined,
  order: undefined,
  promoCode: "",
  promoCodeError: undefined,
  useAccountBalance: true,
  _card: undefined,
};

export const promoCodeSelector = (state: RootState) => {
  const { promoCodeError, promoCode } = state.CheckoutSlice;
  return !promoCodeError ? promoCode : undefined;
};

export const editedSubscriptionCartItemsSelector = (state: RootState) => {
  return state.CheckoutSlice.editSubscriptionsCartItems?.[0]
    ?.subscriptionCartItems;
};
export const orderSelector = (state: RootState) => state.CheckoutSlice.order;
export const orderSubscriptionSelector = (state: RootState) =>
  state.CheckoutSlice?.order?.subscriptions;
export const orderOneTimePurchaseSelector = (state: RootState) =>
  state.CheckoutSlice?.order?.oneTimePurchase;
export const priceOrderSubscriptionsSelector = (state: RootState) =>
  orderSubscriptionSelector(state)?.[0]?.price;
export const priceOrderOneTimePurchasesSelector = (state: RootState) =>
  orderOneTimePurchaseSelector(state)?.price;

export const CheckoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setCheckoutState: (
      state,
      {
        payload,
      }: PayloadAction<{
        key: keyof CheckoutState;
        value: FixMyType;
      }>
    ) => {
      const { key, value } = payload;
      (state as FixMyType)[key] = value;
    },
  },
});

export const { setCheckoutState } = CheckoutSlice.actions;
