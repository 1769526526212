import { Order } from "src/interfaces/API";

export function getStatusColor(order: Order) {
  const CHIP_COLORS = {
    PAID: "info.dark", // Sipariş Alındı                      edit, cancel,   price
    ON_ITS_WAY: "warning.main", // Sipariş Yolda              kargo track,    price
    ON_PREPARATION: "info.dark", // Sipariş Hazırlanıyor      edit, cancel,   price
    ON_DELIVERY: "info.dark", // Sipariş Hazırlandı                           price
    DELIVERED: "success.main", // Teslim Edildi               delivered date, price
    DUE_PAYMENT: "error.main", // Ödeme Alınamadı             cancel,         price
    NOT_DELIVERED: "error.main", // Teslim Edilemedi                          price
    REFUNDED_FULLY: "error.main", // Sipariş Geri Döndü                       price
    REFUNDED_PARTIALLY: "warning.main", // Sipariş Geri Döndü                 price
    CANCELLED: "error.main", // Sipariş İptal Edildi                          price

    CREATED: "warning.main",
    RECREATED: "warning.main",
    REJECTED: "warning.main",
    SKIPPED: "warning.main",
    EDITED: "warning.main",
  };

  return CHIP_COLORS[order.refundStatus ?? order.status] || "warning.main";
}
