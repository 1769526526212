import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "..";

type initStateType = {
  id: string;
  isModifyDeliveryOpen: boolean;
  isEntryDeliveryOpen: boolean;
  isModifyInvoiceOpen: boolean;
  isEntryInvoiceOpen: boolean;
  entrymode: "DELIVERY" | "INVOICE";
};

const initialState: initStateType = {
  id: "",
  isEntryDeliveryOpen: false,
  isEntryInvoiceOpen: false,
  isModifyDeliveryOpen: false,
  isModifyInvoiceOpen: false,
  entrymode: "DELIVERY",
};

type GetAddressSecondParameter = {
  deliveryAddresses: ISingleAddress[];
  invoiceAddresses: IInvoiceAddress[];
  type: "DELIVERY" | "INVOICE";
};

export const getAddress = (
  state: RootState,
  data: GetAddressSecondParameter
) => {
  const { id } = state.AddressModalSlice;
  const { deliveryAddresses, invoiceAddresses, type } = data;

  return deliveryAddresses?.find((address) => address?._id === id);
  // return type !== "delivery"
  //   ? invoiceAddresses?.find((address) => address?._id === id)
  //   : deliveryAddresses?.find((address) => address?._id === id);
};

export const AddressModalSlice = createSlice({
  name: "addressModal",
  initialState,
  reducers: {
    toggleEntryDelivery: (state) => {
      state.isEntryDeliveryOpen = !state.isEntryDeliveryOpen;
    },
    toggleEntryInvoice: (state) => {
      state.isEntryInvoiceOpen = !state.isEntryInvoiceOpen;
    },
    toggleModifyDelivery: (state, action: PayloadAction<string>) => {
      state.isModifyDeliveryOpen = !state.isModifyDeliveryOpen;
      state.id = action.payload;

      if (!state.isModifyDeliveryOpen) {
        state.id = "";
      }
    },
    toggleModifyInvoice: (state, action) => {
      state.isModifyInvoiceOpen = !state.isModifyInvoiceOpen;
      state.id = action.payload;
    },
  },
});

export const {
  toggleEntryDelivery,
  toggleEntryInvoice,
  toggleModifyDelivery,
  toggleModifyInvoice,
} = AddressModalSlice.actions;
