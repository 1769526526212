import type { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { i18n } from "next-i18next";

import type {
  GenerateDistanceSalesContractRequest,
  GenerateDistanceSalesContractResponse,
  PrivateSimulateResponseDto,
  PublicSimulateResponseDto,
  SimulateRequestDto,
} from "src/interfaces/API/SimulateDtos";

import { setCheckoutState } from "src/store/slices/Checkout";
import { showSnackbar } from "src/store/slices/Snackbar";
import {
  open,
  setTransactionData,
  setTransactionResponse,
} from "src/store/slices/TransactionModalSlice";
import { createSnackbarErrorHandler, typeSafeObjectKeys } from "src/utils";

import { BaseAPI, BaseAuthorizedAPI, BaseNextAPI } from "./config";

function handlePromoCodeError(
  msg: ApiI18Response,
  dispatch: ThunkDispatch<any, any, AnyAction>
) {
  const promotionCodeErrorList = [
    "Unfortunately, you're not allowed to use this promo code.",
    "We couldn't find this promo code. You might want to check it again.",
  ];

  if (msg && promotionCodeErrorList.includes(msg.en)) {
    dispatch(setCheckoutState({ key: "promoCodeError", value: msg }));
  }
}

export const CheckoutAPI = BaseAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    publicPrepareCheckout: builder.mutation<
      PublicSimulateResponseDto,
      SimulateRequestDto
    >({
      query: (body) => ({
        url: "/public/user/shopping/simulate",
        method: "POST",
        body,
      }),
      onQueryStarted: (_, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then((res) => {
            dispatch(
              setCheckoutState({
                key: "order",
                value: res.data.order,
              })
            );
          })
          .catch((e) => {
            handlePromoCodeError(e?.error?.data?.message, dispatch);

            createSnackbarErrorHandler(dispatch)(e);
          });
      },
    }),
  }),
});

export const CheckoutPrivateAPI = BaseAuthorizedAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    privatePrepareCheckout: builder.mutation<
      PrivateSimulateResponseDto,
      SimulateRequestDto
    >({
      query: (data) => ({
        url: "/private/user/shopping/simulate",
        method: "POST",
        body: { ...data },
      }),
      onQueryStarted: (_, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then((res) => {
            dispatch(
              setCheckoutState({
                key: "order",
                value: res.data.order,
              })
            );

            dispatch(
              setCheckoutState({
                key: "editedSubscriptions",
                value: res.data.editedSubscriptions,
              })
            );

            dispatch(
              setCheckoutState({
                key: "postponedOneTimePurchase",
                value: res.data.postponedOneTimePurchase,
              })
            );
          })
          .catch((e) => {
            handlePromoCodeError(e?.error?.data?.message, dispatch);
            createSnackbarErrorHandler(dispatch)(e);
          });
      },
    }),
    generateDistanceSalesContract: builder.mutation<
      GenerateDistanceSalesContractResponse,
      GenerateDistanceSalesContractRequest
    >({
      query: (data) => ({
        url: "/private/user/shopping/generate-distance-sales-contract",
        method: "POST",
        body: { ...data },
      }),
      onQueryStarted: (_, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then((res) => {
            dispatch(
              setCheckoutState({
                key: "distanceSalesContract",
                value: res.data.distanceSalesContract,
              })
            );
          })
          .catch((e) => {
            handlePromoCodeError(e?.error?.data?.message, dispatch);
            dispatch(
              setCheckoutState({
                key: "distanceSalesContract",
                value: {},
              })
            );

            createSnackbarErrorHandler(dispatch)(e);
          });
      },
    }),
    buyRequest: builder.mutation<any, { data: FixMyType; is3D: FixMyType }>({
      query: ({ data, is3D }) => ({
        url: is3D
          ? "/private/user/shopping/3ds/buy-request"
          : "/private/user/shopping/buy",
        method: "POST",
        body: { ...data },
      }),
      onQueryStarted: (data, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then((res) => {
            if (data.is3D) {
              dispatch(setTransactionResponse(res.data));
              dispatch(open());
              dispatch(
                setTransactionData({
                  key: "transactionCheckType",
                  value: "BUY",
                })
              );
              dispatch(setTransactionData({ key: "status", value: "WAITING" }));
            }
            return res.data;
          })
          .catch((e) => {
            handlePromoCodeError(e?.error?.data?.message, dispatch);
            createSnackbarErrorHandler(dispatch)(e);
          });
      },
      invalidatesTags: [{ type: "Subscription", id: "LIST" }, "Cards"],
    }),
    createNoteWhenPaymentSuccess: builder.mutation<
      any,
      { _order: string; customLetter: string; dontSendInvoice: boolean }
    >({
      query: (data: {
        _order: string;
        customLetter: string;
        dontSendInvoice: boolean;
      }) => ({
        url: "/private/user/orders/setCustomLetter",
        method: "POST",
        body: { ...data },
      }),
    }),
  }),
});

export const CheckoutAPINextAPI = BaseNextAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    privatePrepareCheckout: builder.mutation<
      PrivateSimulateResponseDto,
      SimulateRequestDto
    >({
      query: (body) => ({
        url: "/private/simulate",
        method: "POST",
        body,
      }),
      onQueryStarted: (_, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then((res) => {
            typeSafeObjectKeys(res.data).forEach((key) => {
              dispatch(
                setCheckoutState({
                  key,
                  value: res.data[key],
                })
              );
            });
          })
          .catch((e: any) => {
            handlePromoCodeError(e?.error?.data?.message, dispatch);
            dispatch(
              showSnackbar({
                msg: e?.error?.data?.message[i18n!.language],
                action: true,
              })
            );
          });
      },
    }),
  }),
});

export const { usePublicPrepareCheckoutMutation } = CheckoutAPI;
export const {
  useGenerateDistanceSalesContractMutation,
  useCreateNoteWhenPaymentSuccessMutation,
  usePrivatePrepareCheckoutMutation,
  useBuyRequestMutation,
} = CheckoutPrivateAPI;

export const {
  usePrivatePrepareCheckoutMutation: useNextPrivatePrepareCheckoutMutation,
} = CheckoutAPINextAPI;
