import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type QuicStep =
  | "intro"
  | "personalInfo"
  | "usedProducts"
  | "periodLast"
  | "periodDuration"
  | "periodIntensity"
  | "normalDayProductUseCount"
  | "busyDayProductUseCount"
  | "productQuestions"
  | "result";
type MenstruationDuration = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export type Intensity = "low" | "middle" | "dense" | "very-dense";
export type ProdcutSubType = "standard" | "super" | "superPlus";
export type ProdcutType = "pad" | "tampon" | "pantyliner";
type ProductTypeObject = Record<ProdcutSubType, number>;
type answerChoice = "yes" | "no";

export type QuizStore = {
  step: QuicStep;
  answers: {
    name: string;
    age: string;
    usedProducts: string[];
    menstruationDuration: MenstruationDuration[];
    cycleDays: number;
    periodIntensity: Record<`day${number}`, Intensity>;
    periodIntensityDays: number[];
    busyDayProductUseCount: Record<ProdcutType, ProductTypeObject>;
    normalDayProductUseCount: Record<ProdcutType, ProductTypeObject>;
    usePantyliner: answerChoice | null;
    pantylinerCount: "1" | "2-3" | "4+" | null;
  };
};

const initialState: QuizStore = {
  step: "intro",
  answers: {
    name: "",
    age: "",
    usedProducts: [],
    menstruationDuration: [1, 2],
    cycleDays: 0,
    periodIntensity: {},
    periodIntensityDays: [],
    busyDayProductUseCount: {
      pad: {
        standard: 0,
        super: 0,
        superPlus: 0,
      },
      tampon: {
        standard: 0,
        super: 0,
        superPlus: 0,
      },
      pantyliner: {
        standard: 0,
        super: 0,
        superPlus: 0,
      },
    },
    normalDayProductUseCount: {
      pad: {
        standard: 0,
        super: 0,
        superPlus: 0,
      },
      tampon: {
        standard: 0,
        super: 0,
        superPlus: 0,
      },
      pantyliner: {
        standard: 0,
        super: 0,
        superPlus: 0,
      },
    },
    usePantyliner: null,
    pantylinerCount: null,
  },
};

export const QuizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    changeStepTo: (state, { payload }: PayloadAction<QuicStep>) => {
      state.step = payload;
    },
    setAnswers: (
      state,
      { payload }: PayloadAction<Partial<QuizStore["answers"]>>
    ) => {
      state.answers = { ...state.answers, ...payload };
    },
    resetAnswers: (state) => {
      state.answers = initialState.answers;
    },
  },
});

export const { changeStepTo, resetAnswers, setAnswers } = QuizSlice.actions;
