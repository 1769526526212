import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartDrawerIsOpen: false,
};

export const DrawerSlice = createSlice({
  name: "cartDrawer",
  initialState,
  reducers: {
    openCartDrawer: (state) => {
      state.cartDrawerIsOpen = true;
    },
    closeCartDrawer: (state) => {
      state.cartDrawerIsOpen = false;
    },
  },
});

export const { openCartDrawer, closeCartDrawer } = DrawerSlice.actions;
