import { MONGO_ID_CURRENCY_TL } from "src/constant/currency";
import type {
  BuyRequest,
  CardInfo,
  DistanceSalesContract,
  GenerateDistanceSalesContractRequest,
  OrderObject,
  SimulateRequestDto,
  SimulateRequestProductListObject,
  SimulateResponseOrderProductObject,
} from "src/interfaces/API/SimulateDtos";
import type { CartItem } from "src/store/slices";
import { isQuantifiableCartItems } from "src/store/slices/Cart";

const cartItemToProducts = (cartItem: CartItem) => {
  if (isQuantifiableCartItems(cartItem)) {
    return [
      {
        _id: cartItem.data._id,
        quantity: cartItem.quantity,
      },
    ];
  }

  return cartItem.data?.items?.map((item) => {
    const { _product, quantity } = item;

    return {
      _id: _product,
      quantity,
    };
  });
};

export const extractSimulateProducts = (
  cartItems: CartItem[],
  currency: string
): SimulateRequestProductListObject => {
  const extractedProductsFromPacket = cartItems?.flatMap(cartItemToProducts);
  const extracedPackets = cartItems
    .filter((x) => x.type === "packet")
    .map((x) => ({ quantity: x.quantity, _packet: x.data._id }));

  const culculatedAmount = cartItems.reduce((acc, item) => {
    const itemPrice = item.data.price[item.purchaseType];

    if (isQuantifiableCartItems(item)) {
      return acc + itemPrice * item.quantity;
    }

    return acc + itemPrice;
  }, 0);

  const price = {
    grossOriginalAmount: culculatedAmount,
    _currency: currency,
  };

  return {
    products: extractedProductsFromPacket,
    price,
    packets: extracedPackets,
  };
};

export const prepareTotalPrice = (
  price: {
    total: string;
  },
  currency: string
): {
  grossOriginalAmount: number;
  _currency: string;
} => ({
  grossOriginalAmount: Number(price.total),
  _currency: currency,
});

interface BaseSimulateRequirment {
  subscriptionCartItems: CartItem[];
  oneTimePurchaseCartItems: CartItem[];
  price: { total: string; cartItems: string };
  promoCode?: string;
}

export const prepareRequestPayload = ({
  subscriptionCartItems,
  oneTimePurchaseCartItems,
  price,
  promoCode,
}: BaseSimulateRequirment) => {
  const subscriptions = extractSimulateProducts(
    subscriptionCartItems,
    MONGO_ID_CURRENCY_TL
  );

  const oneTimePurchase = extractSimulateProducts(
    oneTimePurchaseCartItems,
    MONGO_ID_CURRENCY_TL
  );
  const payload: SimulateRequestDto = {
    price: prepareTotalPrice(price, MONGO_ID_CURRENCY_TL),
  };

  if (subscriptions.products.length) payload.subscriptions = [subscriptions];
  if (oneTimePurchase.products.length)
    payload.oneTimePurchase = oneTimePurchase;
  if (!!promoCode && promoCode.trim()) payload.promoCode = promoCode;

  return payload;
};

export const initializeDistanceSalesContractPayload = ({
  subscriptionCartItems,
  oneTimePurchaseCartItems,
  price,
  promoCode,
}: BaseSimulateRequirment) => {
  const date = new Date().toISOString();

  const simulateRequests = prepareRequestPayload({
    subscriptionCartItems,
    oneTimePurchaseCartItems,
    price,
    promoCode,
  });

  const payload: GenerateDistanceSalesContractRequest = {
    ...simulateRequests,
    preliminaryInformationAgreementDateSigned: date,
    dateSigned: date,
  };

  return payload;
};

interface BuyRequestRequirment extends BaseSimulateRequirment {
  card: CardInfo | null;
  order: OrderObject;
  distanceSalesContract: DistanceSalesContract;
  useAccountBalance?: boolean;
  _card?: string;
}

export const initializeBuyRequestPayload = ({
  subscriptionCartItems: subscriptions,
  oneTimePurchaseCartItems: oneTimePurchase,
  promoCode,
  price,
  card,
  order,
  distanceSalesContract,
  useAccountBalance,
  _card,
}: BuyRequestRequirment) => {
  const { price: _price, ...simulateRequestsExceptPrice } =
    prepareRequestPayload({
      subscriptionCartItems: subscriptions,
      oneTimePurchaseCartItems: oneTimePurchase,
      promoCode,
      price,
    });

  const payload: Partial<BuyRequest> = { ...simulateRequestsExceptPrice };

  payload.price = order.price;
  payload.distanceSalesContract = distanceSalesContract;
  payload.dateSigned = distanceSalesContract.dateSigned;
  payload.preliminaryInformationAgreementDateSigned =
    distanceSalesContract.preliminaryInformationAgreementDateSigned;
  payload.useAccountBalance = useAccountBalance;
  payload._card = _card;

  if (card !== null) {
    payload.card = card;
  }

  return payload;
};

export function makePriceDetails({
  grossOriginalAmount,
  grossAmount,
  grossPromoDiscountAmount,
}: {
  grossOriginalAmount: number;
  grossAmount: number;
  grossPromoDiscountAmount: number;
}) {
  return {
    grossOriginalAmount,
    grossAmount,
    grossPromoDiscountAmount,
    _currency: MONGO_ID_CURRENCY_TL,
  };
}

export const extractPriceDetailsFromOrderProduct = (
  products?: SimulateResponseOrderProductObject[]
) => {
  return products?.reduce(
    (
      acc: {
        grossOriginalAmount: number;
        grossAmount: number;
        grossPromoDiscountAmount: number;
        _currency: string;
      },
      item: SimulateResponseOrderProductObject
    ) => {
      const prices = item.linePrice!;

      return makePriceDetails({
        grossAmount: prices.grossAmount + acc.grossAmount,
        grossOriginalAmount:
          prices.grossOriginalAmount + acc.grossOriginalAmount,
        grossPromoDiscountAmount:
          prices.grossPromoDiscountAmount + acc.grossPromoDiscountAmount,
      });
    },
    makePriceDetails({
      grossOriginalAmount: 0,
      grossAmount: 0,
      grossPromoDiscountAmount: 0,
    })
  );
};
