import { LegacyApiResponse } from "src/interfaces/API";

import { BaseAPI, BaseAuthorizedAPI } from "./config";

export const AddressPublic = BaseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query<ICountry[], void>({
      query: () => "/public/user/countries?query={}",
      transformResponse: (response: LegacyApiResponse<ICountry[]>) =>
        response.documents,
    }),
    getStates: builder.query<IState[], string>({
      query: (countryID) => ({
        url: `/public/user/states?query={"_country":"${countryID}"}`,
        method: "GET",
      }),
      transformResponse: (response: LegacyApiResponse<IState[]>) =>
        response.documents.sort((a, b) => a.name.localeCompare(b.name)),
    }),
    getCities: builder.query<ICity[], string>({
      query: (StateID) => ({
        url: `/public/user/cities?query={"_state":"${StateID}"}`,
        method: "GET",
      }),
      transformResponse: (response: LegacyApiResponse<ICity[]>) =>
        response.documents.sort((a, b) => a.name.localeCompare(b.name)),
    }),
    getNeighborhoods: builder.query<INeighborhood[], string>({
      query: (cityId) => ({
        url: `/public/user/neighborhoods?query={"_city":"${cityId}"}`,
        method: "GET",
      }),
      transformResponse: (response: LegacyApiResponse<INeighborhood[]>) =>
        response.documents.sort((a, b) => a.name.localeCompare(b.name)),
    }),
  }),
});

export const AddressPrivate = BaseAuthorizedAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getDeliveryAddresses: builder.query<ISingleAddress[], void>({
      query: () => "/private/user/delivery-addresses?query={}",
      providesTags: ["Address", "Account"],
      transformResponse: (response: IDeliveryAddressResponse) =>
        response.documents,
    }),
    getInvoiceAddresses: builder.query<IInvoiceAddress[], void>({
      query: () => "/private/user/invoice-addresses?query={}",
      providesTags: ["Address", "Account"],
      transformResponse: (response: IInvoiceAddressResponse) =>
        response.documents,
    }),
    getPrimaryAddress: builder.query<IAddressResponse, void>({
      query: () => "/private/user/delivery-addresses/primary",
      providesTags: ["Address", "Account"],
    }),
    entryDeliveryAddress: builder.mutation<IAddressResponse, IAddressRequest>({
      query: (data: IAddressRequest) => ({
        url: "/private/user/delivery-addresses",
        method: "post",
        body: { ...data },
      }),
      invalidatesTags: ["Address"],
    }),
    setPrimaryDeliveryAddress: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/private/user/delivery-addresses/${id}/primary`,
        method: "post",
      }),
      invalidatesTags: ["Address"],
    }),
    modifyDeliveryAddress: builder.mutation<
      void,
      {
        id: string;
        address: IAddressRequest;
        version: number;
      }
    >({
      query: ({ id, address, version }) => ({
        url: `/private/user/delivery-addresses/${id}?version=${version}`,
        method: "PATCH",
        body: { ...address },
      }),
      invalidatesTags: ["Address"],
    }),
    deleteDeliveryAddress: builder.mutation<
      void,
      { id: string; version: number }
    >({
      query: ({ id, version }) => ({
        url: `/private/user/delivery-addresses/${id}?version=${version}`,
        method: "delete",
      }),
      invalidatesTags: ["Address"],
    }),
    entryInvoiceAddress: builder.mutation<IInvoiceAddress, IAddressRequest>({
      query: (data: IAddressRequest) => ({
        url: "/private/user/invoice-addresses",
        method: "post",
        body: { ...data },
      }),
      invalidatesTags: ["Address"],
    }),
    setPrimaryInvoiceAddress: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/private/user/invoice-addresses/${id}/primary`,
        method: "post",
      }),
      invalidatesTags: ["Address"],
    }),

    modifyInvoiceAddress: builder.mutation<
      void,
      {
        id: string;
        address: IAddressRequest;
        version: number;
      }
    >({
      query: ({ id, address, version }) => ({
        url: `/private/user/invoice-addresses/${id}?version=${version}`,
        method: "PATCH",
        body: { ...address },
      }),
      invalidatesTags: ["Address"],
    }),

    deleteInvoiceAddress: builder.mutation<
      void,
      { id: string; version: number }
    >({
      query: ({ id, version }) => ({
        url: `/private/user/invoice-addresses/${id}?version=${version}`,
        method: "delete",
      }),
      invalidatesTags: ["Address"],
    }),
  }),
});

export const {
  useGetDeliveryAddressesQuery,
  useGetInvoiceAddressesQuery,
  useGetPrimaryAddressQuery,
  useEntryDeliveryAddressMutation,
  useSetPrimaryDeliveryAddressMutation,
  useDeleteDeliveryAddressMutation,
  useModifyDeliveryAddressMutation,
  useEntryInvoiceAddressMutation,
  useSetPrimaryInvoiceAddressMutation,
  useModifyInvoiceAddressMutation,
  useDeleteInvoiceAddressMutation,
} = AddressPrivate;

export const {
  useGetCountriesQuery,
  useGetStatesQuery,
  useGetCitiesQuery,
  useGetNeighborhoodsQuery,
} = AddressPublic;
