import { CartItem, PurchaseType } from "src/store/slices";
import { PacketItem } from "src/types/Packet";

type SegmentCartItemType = {
  name: string;
  product_id: string;
  price: number;
  category: string;
  sku: string;
  url: string;
  bundle_product_names: PacketItem[];
  bundle_product_ids: string[];
  sale_variant?: string;
  quantity?: number;
};

type GoogleAnalyticsCartItemType = {
  item_name: string;
  item_id: string;
  price: number;
  item_variant: string;
  sku: string;
  url: string;
  item_list_names: PacketItem[];
  item_list_id: string[];
  item_category?: string;
  quantity?: number;
};

export const extractPacketProperties = (
  packet: Omit<
    IPacket,
    "pictures" | "intensity" | "usageCount" | "_packetType" | "__v" | "summary"
  >,
  name: string,
  type: "SEGMENT" | "GOOGLE_ANALYTICS",
  cartItemType: "packet" | "product" | "custompacket",
  exteraValues?: { purchaseType: PurchaseType; quantity: number }
) => {
  let result: SegmentCartItemType | GoogleAnalyticsCartItemType;
  if (type === "SEGMENT") {
    result = {
      name,
      product_id: packet._id,
      price: Number(packet.price.subscription?.toFixed(2)),
      category: cartItemType,
      sku: packet.slug,
      bundle_product_names: packet.items, // TODO: fix this after products name add from backend
      bundle_product_ids: packet.items.map((item) => item._product),
      url: window?.location.href,
    };
    if (exteraValues) {
      result["sale_variant"] = exteraValues.purchaseType;
    }
  } else {
    result = {
      item_name: name,
      item_id: packet._id,
      price: Number(packet.price.subscription?.toFixed(2)),
      item_variant: cartItemType,
      sku: packet.slug,
      item_list_names: packet.items,
      item_list_id: packet.items.map((item) => item._product),
      url: window?.location.href,
    };
    if (exteraValues) {
      result["item_category"] = exteraValues.purchaseType;
    }
  }

  if (exteraValues) {
    result["quantity"] = exteraValues.quantity;
    result["price"] = Number(
      packet.price[exteraValues.purchaseType]?.toFixed(2)
    );
  }

  return result;
};

export const extractCartItemProperties = (
  cartItem: CartItem,
  name: string,
  type: "SEGMENT" | "GOOGLE_ANALYTICS"
) => {
  let result: SegmentCartItemType | GoogleAnalyticsCartItemType;
  const exteraValues = {
    purchaseType: cartItem.purchaseType,
    quantity: cartItem.quantity,
  };
  if (cartItem.type === "packet" || cartItem.type === "custompacket") {
    result = extractPacketProperties(
      cartItem.data,
      name,
      type,
      cartItem.type,
      exteraValues
    );
  } else {
    result = extractPacketProperties(
      {
        ...cartItem.data,
        slug: cartItem.data.pageIdentifier,
        items: [],
      },
      name,
      type,
      "product",
      exteraValues
    );
  }

  return result;
};
