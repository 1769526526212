import "../styles/globals.scss";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import type { AppProps, NextWebVitalsMetric } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { appWithTranslation } from "next-i18next";
import nextI18NextConfig from "next-i18next.config";
import { event, GoogleAnalytics } from "nextjs-google-analytics";
import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";

import { NavigationLoading } from "src/components/Common/NavigationLoading";
import { GoogleTagManagerScript } from "src/components/Scripts/GoogleTagManagerScript";
import { IS_PRODUCTION, NEXT_PUBLIC_GA_MEASUREMENT_ID } from "src/constant";
import { AnalyticsProvider } from "src/core/analytics";
import { RootSeo } from "src/core/seo/RootSeo";
import { store, persistor } from "src/store";
import { theme } from "src/themes";
import createEmotionCache from "src/utils/createEmotionCache";

const DynamicConfirmationModal = dynamic(
  () =>
    import("src/components/Common/ConfirmationModal").then(
      (x) => x.ConfirmationModal
    ),
  {
    loading: () => null,
  }
);
const DynamicSnackbar = dynamic(
  () => import("src/components/Common/Snackbar").then((x) => x.Snackbar),
  {
    loading: () => null,
  }
);
const DynamicTransactionModal = dynamic(
  () =>
    import("src/components/Common/TransactionModal").then(
      (x) => x.TransactionModal
    ),
  {
    loading: () => null,
  }
);
const DynamicCartDrawer = dynamic(
  () => import("src/components/Cart/CartDrawer").then((x) => x.CartDrawer),
  {
    loading: () => null,
  }
);

const DynamicCustomizePacketModal = dynamic(
  () =>
    import("src/components/CustomPacket/CustomizePacketModal").then(
      (x) => x.CustomizePacketModal
    ),
  {
    loading: () => null,
  }
);

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  event(metric.name, {
    category:
      metric.label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    value: Math.round(
      metric.name === "CLS" ? metric.value * 1000 : metric.value
    ), // values must be integers
    label: metric.id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  });
}

const MyApp = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: MyAppProps) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CacheProvider value={emotionCache}>
          <Head>
            <meta
              name="viewport"
              content="initial-scale=1, width=device-width"
            />
          </Head>
          <RootSeo />
          <GoogleTagManagerScript />
          <GoogleAnalytics
            trackPageViews={false}
            gaMeasurementId={NEXT_PUBLIC_GA_MEASUREMENT_ID}
            defaultConsent="granted"
            debugMode={!IS_PRODUCTION}
          />

          <AnalyticsProvider>
            <ThemeProvider theme={theme}>
              <DynamicConfirmationModal />
              <NavigationLoading>
                <CssBaseline />
                <Component {...pageProps} />

                <DynamicCustomizePacketModal />
                <DynamicCartDrawer />
                <DynamicTransactionModal />

                {/*
                  We put here because we wanna access to here in any point of project
                  for open toast message only use below syntax:
                    1- add toast helper with `import showSnackbar from "src/store/slices` ";
                    2- dispatch(showSnackbar({msg:"beije TOAST MESSAGE", action: true});
                */}
                <DynamicSnackbar />
                {/* <PublicPopUp /> */}
              </NavigationLoading>
            </ThemeProvider>
          </AnalyticsProvider>
        </CacheProvider>
      </PersistGate>
    </Provider>
  );
};

export default appWithTranslation(MyApp, nextI18NextConfig);
