import { i18n } from "next-i18next";

import { JSendApiResponse } from "src/interfaces/API";
import {
  editPacketFromSubscription,
  showSnackbar,
  toggleCustomizeModalClose,
} from "src/store/slices";

import { BaseAPI } from "./config";
import { addToCart, updateCartItem } from "../slices/Cart";
import type { RootState } from "../store";

type GetPacketArg = {
  limit?: number;
  page?: number;
  filter?: string;
  sort?: string;
};

type GetPacketsTypeByIDArg = {
  limit?: number;
  page?: number;
  id: string;
};

export const PacketsAPI = BaseAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getPackets: builder.query<JSendApiResponse<IPacket[]>, GetPacketArg | void>(
      {
        query: ({
          limit = 12,
          page = 0,
          filter = "",
          sort = "",
        }: GetPacketArg) =>
          `/packets_service/v2/packet?limit=${limit}&page=${page}&${filter}&sort=${sort}`,
      }
    ),
    getPopularPackets: builder.query<IPacket[], void>({
      query: () => "/packets_service/v2/packet/popular",
      transformResponse: (response: JSendApiResponse<IPacket[]>) =>
        response.data,
    }),
    getPacketByID: builder.query<IPacket, string>({
      query: (id) => `/packets_service/v2/packet/${id}`,
      transformResponse: (response: JSendApiResponse<IPacket>) => response.data,
    }),
    getPacketBySlug: builder.query<IPacket, string>({
      query: (slug) => `/packets_service/v2/packet/slug/${slug}`,
      transformResponse: (response: JSendApiResponse<IPacket>) => response.data,
    }),
    getPacketTypes: builder.query<IPacketType[], void>({
      query: () => "/packets_service/v2/packet-type",
      transformResponse: (response: JSendApiResponse<IPacketType[]>) =>
        response.data,
    }),
    getPacketTypeByID: builder.query<
      IPacketTypeWithPackets,
      GetPacketsTypeByIDArg
    >({
      query: ({ id, limit = 12, page = 0 }: GetPacketsTypeByIDArg) =>
        `/packets_service/v2/packet-type/${id}/?limit=${limit}&page=${page}`,
      transformResponse: (response: JSendApiResponse<IPacketTypeWithPackets>) =>
        response.data,
    }),
    getPacketTypeBySlug: builder.query<IPacketTypeWithPackets, string>({
      query: (slug) => `/packets_service/v2/packet-type/slug/${slug}`,
      transformResponse: (response: JSendApiResponse<IPacketTypeWithPackets>) =>
        response.data,
    }),
    getSortedPacketableProductType: builder.query<IPacketType[], void>({
      query: () =>
        `/packets_service/v2/packet-type?filter=${JSON.stringify({
          algorithm: "ProductType",
        })}`,
      transformResponse: (response: JSendApiResponse<IPacketType[]>) =>
        [...response.data].sort(({ sortOrder: a }, { sortOrder: b }) => a - b),
    }),
    createCustomPacket: builder.mutation<
      JSendApiResponse<IPacket>,
      IPacketItem[]
    >({
      query: (packetItems) => ({
        url: `/packets_service/v2/packet/`,
        method: "POST",
        body: { items: packetItems },
      }),
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        queryFulfilled.then((responsePacket) => {
          const { action: customPacketDialogAction, selectedPacket } = (
            getState() as RootState
          ).CustomPacket;

          const cartItems = (getState() as RootState).CartSlice.items;

          if (customPacketDialogAction === "ADD_TO_CART") {
            const isSelectedPacketExistInCartItems =
              selectedPacket &&
              cartItems.find((_) => _.data._id === selectedPacket._id);

            const isGeneratedCustomPacketExistInCart = cartItems.find(
              (x) => x.data._id === responsePacket.data.data._id
            );

            if (isGeneratedCustomPacketExistInCart) {
              dispatch(
                showSnackbar({
                  msg: i18n?.t("nav:already-exist-in-cart") ?? "",
                })
              );
              dispatch(toggleCustomizeModalClose());
              return;
            }

            if (isSelectedPacketExistInCartItems) {
              dispatch(
                updateCartItem({
                  id: selectedPacket._id,
                  newItem: {
                    type: "packet",
                    data: responsePacket.data.data,
                    quantity: 1,
                  },
                })
              );
              dispatch(toggleCustomizeModalClose());
              return;
            }

            dispatch(
              addToCart({
                type: "packet",
                purchaseType: "subscription",
                data: responsePacket.data.data,
              })
            );
            dispatch(toggleCustomizeModalClose());
            return;
          }

          if (customPacketDialogAction === "EDIT_SUBSCRIPTION") {
            dispatch(editPacketFromSubscription(responsePacket.data.data));
            dispatch(toggleCustomizeModalClose());
            return;
          }
        });
      },
    }),
    getSuggestionPackets: builder.query<IPacket[], string | void>({
      query: (params?: string) => `/packets_service/v2/packet${params ?? ""}`,
      transformResponse: (response: JSendApiResponse<IPacket[]>) => {
        const NUMBER_OF_SUGGESTION_ITEM_TO_SHOW = 3;

        return response.data.slice(0, NUMBER_OF_SUGGESTION_ITEM_TO_SHOW);
      },
    }),
  }),
});

export const {
  useGetPacketsQuery,
  useGetPopularPacketsQuery,
  useGetPacketByIDQuery,
  useGetPacketBySlugQuery,
  useGetPacketTypesQuery,
  useGetPacketTypeByIDQuery,
  useGetPacketTypeBySlugQuery,
  useCreateCustomPacketMutation,
  useGetSuggestionPacketsQuery,
  useGetSortedPacketableProductTypeQuery,
} = PacketsAPI;
