import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DistanceSalesContract } from "src/interfaces/API/SimulateDtos";

type OrdersTypes = {
  isDistanceSalesContractModalOpen: boolean;
  distanceSaleContract?: DistanceSalesContract;
  isPreliminaryInfoContractModalOpen: boolean;
};

const initialState: OrdersTypes = {
  isDistanceSalesContractModalOpen: false,
  isPreliminaryInfoContractModalOpen: false,
};

export const OrdersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setDistanceSaleContract: (
      state,
      { payload }: PayloadAction<OrdersTypes["distanceSaleContract"]>
    ) => {
      state.distanceSaleContract = payload;
    },
    setDistanceSalesContractModalOpen: (state) => {
      state.isDistanceSalesContractModalOpen = true;
    },
    setDistanceSalesContractModalClose: (state) => {
      state.isDistanceSalesContractModalOpen = false;
    },
    setPreliminaryInfoContractModalOpen: (state) => {
      state.isPreliminaryInfoContractModalOpen = true;
    },
    setPreliminaryInfoContractModalClose: (state) => {
      state.isPreliminaryInfoContractModalOpen = false;
    },
  },
});

export const {
  setDistanceSalesContractModalOpen,
  setDistanceSalesContractModalClose,
  setPreliminaryInfoContractModalOpen,
  setPreliminaryInfoContractModalClose,
  setDistanceSaleContract,
} = OrdersSlice.actions;
