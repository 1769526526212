export const API_SERVICES = {
  faq: {
    getById: (id: string) => `/faq_service/v2/faq/${id}`,
    query: ({ filter, limit = 50 }: { filter?: string; limit?: number }) =>
      `/faq_service/v2/faq?limit=${limit}${filter ?? ""}`,
    faqTypeSlug: (slug: string) => `/faq_service/v2/faq-type/slug/${slug}`,
    faqTypes: () => `/faq_service/v2/faq-type/?limit=10&sort=sortOrder`,
    faqSubType: () => `/faq_service/v1/faq-sub-type/?limit=50`,
  },
};
