import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { PartialBy } from "src/types/Utilities";

export type PurchaseType = "oneTimePurchase" | "subscription";

type ProductCartItem = {
  type: "product";
  data: IProduct;
  purchaseType: "oneTimePurchase";
  quantity: number;
  isQuantifiable: true;
  createdAt: number;
};

export type PacketCartItem = {
  type: "packet";
  data: IPacket;
  purchaseType: PurchaseType;
  quantity: 1;
  isQuantifiable: false;
  createdAt: number;
};

// type use for analytics events only
export type CustomPacketCartItem = {
  type: "custompacket";
  data: IPacket;
  purchaseType: PurchaseType;
  quantity: 1;
  isQuantifiable: false;
  createdAt: number;
};

export type DonationCartItem = {
  type: "donation";
  data: IProduct;
  purchaseType: PurchaseType;
  quantity: number;
  isQuantifiable: true;
  createdAt: number;
};

type QuantifiableCartItem = DonationCartItem | ProductCartItem;
export type SubscriptionCartItems = DonationCartItem | PacketCartItem;
export type CartItem =
  | PacketCartItem
  | QuantifiableCartItem
  | CustomPacketCartItem;

export type ICart = {
  items: CartItem[];
};

const modifiedProductQuantity = (
  cartItem: QuantifiableCartItem,
  type: "DECREASE" | "INCREASE"
) => {
  let cartItemQuantites = cartItem.quantity;
  if (type === "DECREASE") {
    cartItemQuantites -= 1;
    return cartItemQuantites;
  }

  cartItemQuantites += 1;
  return cartItemQuantites;
};

export function isQuantifiableCartItems(
  cartItem: CartItem
): cartItem is QuantifiableCartItem {
  return (
    typeof cartItem.isQuantifiable !== "undefined" && !!cartItem.isQuantifiable
  );
}

export function isProductCartItems(
  cartItem: CartItem
): cartItem is ProductCartItem {
  return typeof cartItem.type !== "undefined" && cartItem.type === "product";
}

export function isPacketCartItems(
  cartItem: CartItem
): cartItem is PacketCartItem {
  return typeof cartItem.type !== "undefined" && cartItem.type === "packet";
}

export const cartNotEmpty = createSelector(
  (state: ICart) => state.items,
  (items) => items.length !== 0
);

export const cartPrice = createSelector(
  (state: ICart) => state.items,
  (items) => {
    const cartTotalPrice = items.reduce((acc, item) => {
      const packetPrice =
        item.purchaseType === "subscription"
          ? item.data.price.subscription
          : item.data.price.oneTimePurchase;

      const price = packetPrice * item.quantity;
      return acc + price;
    }, 0);

    return {
      cartItems: cartTotalPrice.toFixed(2),
      total: cartTotalPrice.toFixed(2),
    };
  }
);

const initialState: ICart = {
  items: [],
};

export const CartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    emptyCart: (state) => {
      state.items = [];
    },
    increaseQuantity: (
      state,
      { payload }: PayloadAction<{ productCartItemId: string }>
    ) => {
      const product = state.items.find(
        (_) => _.data._id === payload.productCartItemId
      );

      if (product && isQuantifiableCartItems(product)) {
        product.quantity = modifiedProductQuantity(product, "INCREASE");
      }
    },
    decreaseQuantity: (
      state,
      { payload }: PayloadAction<{ productCartItemId: string }>
    ) => {
      const product = state.items.find(
        (_) => _.data._id === payload.productCartItemId
      );
      if (product && isQuantifiableCartItems(product)) {
        product.quantity = modifiedProductQuantity(product, "DECREASE");
      }
    },
    addToCart: (
      state,
      {
        payload,
      }: PayloadAction<
        PartialBy<CartItem, "createdAt" | "isQuantifiable" | "quantity">
      >
    ) => {
      const {
        data: { _id: itemId },
      } = payload;
      const cartItem = state.items.find((item) => item.data._id === itemId);

      const isQuantifiable = payload.type !== "packet";
      const quantity = isQuantifiable ? payload.quantity : 1;

      if (!cartItem) {
        payload.createdAt = new Date().getTime();
        state.items.push({ ...payload, isQuantifiable, quantity } as CartItem);
        return;
      }

      if (isQuantifiableCartItems(cartItem)) {
        cartItem.quantity = modifiedProductQuantity(cartItem, "INCREASE");
      }
    },
    removeFromCart: (state, action: PayloadAction<string>) => {
      state.items = state.items.filter(
        (item) => item.data._id !== action.payload
      );
    },
    updateCartItem: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        newItem: PartialBy<
          CartItem,
          "createdAt" | "isQuantifiable" | "quantity" | "purchaseType"
        >;
      }>
    ) => {
      state.items = state.items.map((item) =>
        item.data._id === payload.id
          ? ({
              ...payload.newItem,
              purchaseType: payload.newItem.purchaseType ?? item.purchaseType,
              createdAt: item.createdAt,
              quantity: payload.newItem.quantity ?? 1,
              isQuantifiable: payload.newItem.isQuantifiable ?? false,
            } as CartItem)
          : item
      );
    },
    changePaymentOption: (
      state,
      {
        payload,
      }: PayloadAction<{ cartItemId: string; purchaseType: PurchaseType }>
    ) => {
      const { cartItemId, purchaseType } = payload;
      const cartItem = state.items.find((item) => item.data._id === cartItemId);
      if (cartItem) cartItem.purchaseType = purchaseType;
    },
  },
});

export const subscriptions = createSelector(
  (state: ICart) => state.items,
  (items: ICart["items"]) =>
    items?.filter((item) => item.purchaseType === "subscription").sort()
);

export const subscriptionsPackets = createSelector(
  (state: ICart) => state.items,
  (items: ICart["items"]) =>
    items
      .filter(
        (item) => item.purchaseType === "subscription" && item.type === "packet"
      )
      .sort()
);

export const oneTimePurchase = createSelector(
  (state: ICart) => state.items,
  (items: ICart["items"]) =>
    items.filter((item) => item.purchaseType === "oneTimePurchase").sort()
);

export const {
  addToCart,
  emptyCart,
  removeFromCart,
  changePaymentOption,
  decreaseQuantity,
  increaseQuantity,
  updateCartItem,
} = CartSlice.actions;
