import { useMemo } from "react";

import { EditSubscription } from "src/store/slices";

import { useGenerateSimulateFromSubscription } from "./useGenerateSimulateFromSubscription";

export function useEditSubscription(
  subscription?: EditSubscription["subscription"]
) {
  const { packets, ...rest } =
    useGenerateSimulateFromSubscription(subscription);

  const sortedPackets = useMemo(() => {
    return packets.data?.data
      .map((p) => {
        const maybe = subscription?.packets?.find((x) => x._packet === p._id);
        return { ...p, sortOrder: maybe?.sortOrder };
      })
      .sort((a, b) => a.sortOrder! - b.sortOrder!);
  }, [packets, subscription]);

  return { ...rest, packets: { ...packets, sortedData: sortedPackets } };
}
