import { MAX_AGE_YEARS, MIN_AGE_YEARS } from "src/constant";

export const getDateWithShiftedYears = (shift: number) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + shift);
  return date;
};

export const getMinimumAllowedBirthDate = () =>
  getDateWithShiftedYears(-MAX_AGE_YEARS);
export const getMaximumAllowedBirthDate = () =>
  getDateWithShiftedYears(-MIN_AGE_YEARS);

export const getAgeInYears = (date: Date) => {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
