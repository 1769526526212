import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

import { OrderStatus } from "src/interfaces/API";

import { useGetSubscriptionLastOrderStatusMutation } from "src/store/apis";

export function useSubscriptionLastOrderStatus(id: string) {
  const router = useRouter();
  const [orderStatus, setOrderStatus] = useState<OrderStatus | null>(null);

  const [getSubscriptionLastOrderStatus, { isLoading, isError, isSuccess }] =
    useGetSubscriptionLastOrderStatusMutation();

  const redirectToSubscription = useCallback(
    () => router.replace("/account/"),
    [router]
  );

  useEffect(() => {
    getSubscriptionLastOrderStatus({ subscriptionId: id })
      .unwrap()
      .then((data) => setOrderStatus(data.status))
      .catch(redirectToSubscription);
  }, [getSubscriptionLastOrderStatus, id, redirectToSubscription]);

  return { orderStatus, isLoading, isError, isSuccess };
}
