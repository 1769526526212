import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

type FilterPacketsType = {
  filteredPackets: IPacket[];
  filterPacketTypeByID: string[];
  filterProductSubTypeByID: string[];
  filterIntensityByID: string[];
  isNotFound: boolean;
  isLoading: boolean;
  filterTotalItems: number;
  filterPage: number;
  selectedFilterCount: number;
};

export const fetchPacketTypes: (
  packetTypes?: IPacketType[]
) => IFilter[] | undefined = createSelector(
  [(packetTypes?: IPacketType[]) => packetTypes],
  (packetTypes) =>
    packetTypes?.map((packetType) => ({
      value: packetType._id,
      name: packetType.name,
      productType: packetType._productType,
      algorithm: packetType.algorithm,
      sortOrder: packetType.sortOrder,
    }))
);

export const isFiltersCleared = createSelector(
  [(state: any) => state],
  (state: FilterPacketsType) =>
    state.filterPacketTypeByID.length === 0 &&
    state.filterProductSubTypeByID.length === 0 &&
    state.filterIntensityByID.length === 0
);

export const fetchProductSubTypes: (
  productTypes?: IProductSubType[]
) => IFilter[] | undefined = createSelector(
  [(productSubTypes?: IProductSubType[]) => productSubTypes],
  (productSubTypes) =>
    productSubTypes?.map((productSubType) => ({
      value: productSubType.id,
      name: productSubType.name,
      typeId: productSubType.typeId,
      sortOrder: productSubType.sortOrder,
    }))
);

const initialState: FilterPacketsType = {
  filteredPackets: [],
  filterPacketTypeByID: [],
  filterProductSubTypeByID: [],
  filterIntensityByID: [],
  isNotFound: false,
  isLoading: false,
  filterTotalItems: 0,
  filterPage: 0,
  selectedFilterCount: 0,
};

export const FilterPacketsSlice = createSlice({
  name: "filterPackets",
  initialState,
  reducers: {
    toggleFilteredByIDS: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        type: keyof Pick<
          FilterPacketsType,
          | "filterPacketTypeByID"
          | "filterProductSubTypeByID"
          | "filterIntensityByID"
        >;
      }>
    ) => {
      const { id, type } = payload;
      const isIDExist = state[type].includes(id);
      if (isIDExist) {
        const newIDS = state[type].filter((item: string) => item !== id);
        state[type] = newIDS;
      } else {
        state[type].push(id);
      }
    },
    applyFilter: (state, { payload }: PayloadAction<IPacket[]>) => {
      const filtered: IPacket[] = [...payload];
      state.filteredPackets = filtered;
    },
    clearFilter: (state) => {
      state.filteredPackets = [];
      state.filterPacketTypeByID = [];
      state.filterProductSubTypeByID = [];
      state.filterIntensityByID = [];
      state.filterTotalItems = 0;
      state.filterPage = 0;
      state.selectedFilterCount = 0;
    },
    setFilterLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setFilterTotalItems: (state, { payload }: PayloadAction<number>) => {
      state.filterTotalItems = payload;
    },
    setFilterPage: (state, { payload }: PayloadAction<number>) => {
      state.filterPage = payload;
    },
    setSelectedFilterCount: (state, { payload }: PayloadAction<number>) => {
      state.selectedFilterCount = payload;
    },
  },
});

export const {
  toggleFilteredByIDS,
  applyFilter,
  clearFilter,
  setFilterLoading,
  setFilterTotalItems,
  setFilterPage,
  setSelectedFilterCount,
} = FilterPacketsSlice.actions;
