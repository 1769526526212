export function englishify(name: string) {
  // Change all letters to lowercase
  name = name.toLocaleLowerCase("tr-TR");

  // Remove all turkish words from the name
  const charMap = [
    { turkish: "ç", english: "c" },
    { turkish: "ğ", english: "g" },
    { turkish: "ı", english: "i" },
    { turkish: "ö", english: "o" },
    { turkish: "ş", english: "s" },
    { turkish: "ü", english: "u" },
  ];
  charMap.forEach((x) => {
    name = name.replace(new RegExp(x.turkish, "g"), x.english);
  });

  return name;
}

export const formatPrice = (price: number) =>
  new Intl.NumberFormat("tr-TR", { style: "currency", currency: "TRY" }).format(
    price
  );

export function formatLocaleDate(date: string | Date, locale: string) {
  if (typeof date === "string") {
    date = new Date(date);
  }
  return date.toLocaleDateString(locale, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

/**
 * This function is used to combine the packet items names into a single name with locale
 * @param names
 * @param locale
 * @returns
 */
export const combineNamesByLocale = (names: string[], locale: string) => {
  return typeof Intl.ListFormat === "undefined"
    ? names.join(", ")
    : new Intl.ListFormat(locale, {
        style: "long",
        type: "conjunction",
      }).format(names);
};

const pow = (n: number): number => 10 ** n;
const getTurkishNumberSuffix = (number: number): string => {
  // Zero is exception
  if (number === 0) {
    return "ı";
  }

  const suffixes: {
    character: string;
    endings: number[];
  }[] = [
    {
      character: "i",
      endings: [1, 2, 5, 7, 8, 20, 50, 70, 80, pow(3), pow(4), pow(5)],
    },
    {
      character: "ı",
      endings: [6, 7, 40, 60, pow(9), pow(10), pow(11)],
    },
    {
      character: "u",
      endings: [9, 10, 30, pow(6), pow(7), pow(8)],
    },
    {
      character: "ü",
      endings: [3, 4, 100],
    },
  ];

  let tempNumber = number;
  const positions = [10, 100, 1000, pow(6), pow(9), pow(12)];
  for (let i = 0; i < positions.length; i += 1) {
    const position = positions[i];
    const remaining = tempNumber % position;
    if (remaining > 0) {
      const suffix = suffixes.find((x) => x.endings.includes(remaining));
      if (suffix) {
        return suffix.character;
      }
    }
    tempNumber -= remaining;
  }

  // Suffix always same after 10^12
  return "u";
};

/**
 * Gets the number and returns the number with the correct suffix
 * e.g:
 * 0 -> 0'lı
 * 1 -> 1'li
 * 4 -> 4'lü
 * 10 -> 10'lu
 */
export const addTurkishMorphologicalDerivativesToNumber = (number: number) => {
  const suffix = getTurkishNumberSuffix(number);
  return `${number}'l${suffix}`;
};
