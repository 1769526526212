export const TRIAL_PACKET_ID = "6054d073e113970f3162b0ce";

export const TRIAL_PACKET_PAGEIDENTIFIER = "trial";

export const TRIAL_PAD_PACKET_ID = "6054d3635e48f60f3f715c55";

export const TRIAL_TAMPON_PACKET_ID = "6172d3517cb2113a36f11ea9";

export const DONATION_PRODUCT_TYPE_ID = "61274291447ff844f3cc4437";

export const DONATION_PRODUCT_TYPE_PAGEIDENTIFIER = "donation";

export const DONATION_PAD_PRODUCT_ID = "61275a8e447ff844f3cc47ee";

export const STANDARD_TAMPON_SUB_TYPE_ID = "60942d84188ee1c2a359280e";
export const TAMPON_TRIAL_PACK_SUB_TYPE_ID = "6172d1907cb2113a36f11e7b";

export const INTENSITIES = [
  { name: { tr: "Hafif", en: "Low" }, value: "Light" },
  { name: { tr: "Orta", en: "Medium" }, value: "Regular" },
  { name: { tr: "Yoğun", en: "High" }, value: "Heavy" },
  { name: { tr: "Çok Yoğun", en: "Very high" }, value: "Ultra Heavy" },
];

export const OUT_OF_STOCK_PACKETS_SUB_TYPE: string[] = [];
export const OUT_OF_STOCK_PRODUCTS_WHICH_SHOWS_AS_PACKETS_SUB_TYPE: string[] =
  [];
