import type { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { i18n } from "next-i18next";

import { showSnackbar } from "../store/slices";

function isObject(obj: unknown): obj is Record<string, unknown> {
  return typeof obj === "object" && obj !== null;
}

function isI18nMessage(obj: unknown): obj is ApiI18Response {
  return isObject(obj) && "tr" in obj && "en" in obj;
}

export function getLocaleMessage(message: unknown) {
  if (typeof message === "string") {
    return message;
  } else if (isI18nMessage(message)) {
    return message[(i18n?.language as "tr" | "en") ?? "tr"];
  }
}

export function propOrSelf(obj: unknown, prop: string) {
  if (isObject(obj) && prop in obj) {
    return obj[prop];
  }
  return obj;
}

export function getErrorMessage(error: unknown) {
  // Errors thrown in fetchFn of baseQuery
  error = propOrSelf(error, "error");

  // Error responses from server
  error = propOrSelf(error, "data");

  if (typeof error === "string") {
    return error;
  } else if (isObject(error) && "message" in error) {
    return getLocaleMessage(error.message);
  } else {
    console.error(["Unhandled error type: ", error]);
  }
}

export function createSnackbarErrorHandler(dispatch: Dispatch<AnyAction>) {
  return (error: unknown) => {
    const msg = getErrorMessage(error);
    if (msg) {
      dispatch(
        showSnackbar({
          msg,
          variant: "error",
        })
      );
    }
  };
}

export function nop() {
  return;
}
