import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Product } from "src/interfaces/API";

import { SubscriptionDetail } from "src/types/Account";

type SubscriptionPacketItemWithCreatedAt = {
  _packet: string;
  quantity: number;
  sortOrder: number;
};

type SubscriptionForEdit = Omit<SubscriptionDetail, "packets"> & {
  packets?: SubscriptionPacketItemWithCreatedAt[];
};

export type EditSubscription = {
  subscription?: SubscriptionForEdit;
  selectedEditPacketId?: string;
  selectedEditProductId?: string;
  isDirty: boolean;
  card?: string;
};

const initialState: EditSubscription = {
  subscription: undefined,
  selectedEditPacketId: undefined,
  selectedEditProductId: undefined,
  isDirty: false,
  card: undefined,
};

export const EditSubscriptionSlice = createSlice({
  name: "editSubscription",
  initialState,
  reducers: {
    setSubscription: (
      state,
      { payload }: PayloadAction<SubscriptionDetail>
    ) => {
      const result = payload.packets?.map((x, index) => ({
        ...x,
        sortOrder: index, // payload.packets!.length -
      }));
      state.subscription = { ...payload, packets: result };
    },
    deletePacketFromSubscription: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      const packets = state.subscription?.packets?.filter(
        (x) => x._packet !== payload
      );

      state.isDirty = true;
      if (state.subscription) state.subscription.packets = packets;
    },
    deleteProductsFromSubscription: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      const products = state.subscription?.products?.filter(
        (x) => x._id !== payload
      );

      if (!products) return;

      state.isDirty = true;
      if (state.subscription) state.subscription.products = products;
    },
    setSelectedEditPacketId: (
      state,
      { payload: packetId }: PayloadAction<string>
    ) => {
      state.selectedEditPacketId = packetId;
    },
    setSelectedEditProductId: (
      state,
      { payload: productId }: PayloadAction<string>
    ) => {
      state.selectedEditProductId = productId;
    },
    editPacketFromSubscription: (
      state,
      { payload: packet }: PayloadAction<IPacket>
    ) => {
      if (!state.subscription) return;

      state.isDirty = true;
      state.subscription.packets = state.subscription.packets?.map((p) => {
        if (p._packet === state.selectedEditPacketId)
          return { ...p, _packet: packet._id };
        return p;
      });
    },
    setDirty: (state) => {
      state.isDirty = true;
    },
    editProductsFromSubscription: (
      state,
      {
        payload,
      }: PayloadAction<{ product: Product; _id: string; quantity: number }>
    ) => {
      if (!state.subscription) return;

      const resultProducts = state.subscription.products.map((p) => {
        if (p._id === state.selectedEditProductId) return payload;
        return p;
      });

      state.subscription.products = resultProducts;
    },
    setSelectedSubscriptionCard: (
      state,
      { payload }: PayloadAction<{ card: string; isDirty: boolean }>
    ) => {
      state.card = payload.card;
      state.isDirty = payload.isDirty;
    },
  },
});

export const {
  setSubscription,
  deletePacketFromSubscription,
  deleteProductsFromSubscription,
  setSelectedEditPacketId,
  setSelectedEditProductId,
  editPacketFromSubscription,
  editProductsFromSubscription,
  setDirty,
  setSelectedSubscriptionCard,
} = EditSubscriptionSlice.actions;
