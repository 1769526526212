import { PURCHASABLE_ITEMS_ID } from "src/constant";
import { LegacyApiResponse } from "src/interfaces/API";
import { getSubTypePriority } from "src/utils/packet";

import { BaseAPI } from "./config";

export const ProductAPI = BaseAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getProductTypes: builder.query<IClientProductType[], void>({
      query: () => "/public/user/categories/menstrual/product-types?query={}",
      providesTags: ["ProductTypes"],
      transformResponse: (res: LegacyApiResponse<IProductType[]>) => {
        const PURCHASABLE_ITEMS = Object.freeze(PURCHASABLE_ITEMS_ID);

        return res.documents.map((type) => ({
          id: type._id,
          ...type,
          isPurchasable: PURCHASABLE_ITEMS.includes(type._id),
        }));
      },
    }),
    getProductSubTypes: builder.query<IProductSubType[], void>({
      query: () =>
        "/public/user/categories/menstrual/product-sub-types?query={}",
      providesTags: ["ProductSubTypes"],
      transformResponse: (res: LegacyApiResponse<IProductSubType[]>) =>
        res.documents.map((subType) => ({
          ...subType,
          id: subType._id,
          // TODO: this type of changing api is not maintianable
          typeId: subType._productType,
          // TODO: check usage of this! must be replaced with sorderOrder
          priority: getSubTypePriority(subType),
        })),
    }),
    getProducts: builder.query<IProduct[], void>({
      query: () => ({
        url: "/public/user/products/",
        method: "GET",
      }),
      providesTags: ["Products"],
      transformResponse: (res: { products: IProduct[] }) =>
        res.products.map((product) => ({
          ...product,
          typeId: product._productType,
          subTypeId: product._productSubType,
          price: (product.price as FixMyType).grossAmount,
          APIPrice: product.price as FixMyType,
        })),
    }),
    /**
     * @deprecated
     * This method was used in a loop to extract all the products from the API.
     * So we created a new method to get all the products at once.
     * See `getProducts` method.
     */
    getProductsOfType: builder.query<IProduct[], any>({
      query: (data) => ({
        url: "/public/user/products/get-of-product-type",
        method: "POST",
        body: { _productType: data.typeId },
      }),
      providesTags: ["ProductsOfType"],
      transformResponse: (res: { products: IProduct[] }) =>
        res.products.map((product) => ({
          ...product,
          typeId: product._productType,
          subTypeId: product._productSubType,
          price: (product.price as FixMyType).grossAmount,
          APIPrice: product.price as FixMyType,
        })),
    }),
  }),
});

export const {
  useGetProductTypesQuery,
  useGetProductSubTypesQuery,
  useGetProductsOfTypeQuery,
  useGetProductsQuery,
} = ProductAPI;
