import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { CustomPacketItem, CustomPacketItems } from "src/types/Packet";
import { combinationWithRepetition } from "src/utils";

export type ICustomPacketStore = {
  packet: { items: CustomPacketItems };
  isDrawerOpen: boolean;
  isCustomizeModalOpen: boolean;
  selectedPacket?: IPacket;
  action: "ADD_TO_CART" | "EDIT_SUBSCRIPTION";
};

const initialState: ICustomPacketStore = {
  packet: { items: [] },
  isDrawerOpen: false,
  isCustomizeModalOpen: false,
  selectedPacket: undefined,
  action: "ADD_TO_CART",
};

export const productItemsFromCustomPacketSelector = createSelector(
  [
    (products: IProduct[]) => products,
    (_: any, customPacketItems: CustomPacketItems) => customPacketItems,
  ],
  (products: IProduct[], customPacketItems: CustomPacketItems) =>
    customPacketItems.map((item) => {
      const { userSelectedQuantity, _productSubType } = item;
      const productOfSubtype = products.filter(
        (p) => p.subTypeId === _productSubType
      );

      return combinationWithRepetition(productOfSubtype, 3)
        .concat(combinationWithRepetition(productOfSubtype, 2))
        .concat(combinationWithRepetition(productOfSubtype, 1))
        .map((pair: IProduct[]) => ({
          pair,
          size: pair.reduce((acc, _) => acc + _.productSize, 0),
          price: pair.reduce((acc, _) => acc + _.price.subscription, 0),
        }))
        .filter((_) => _.size === userSelectedQuantity)
        .reduce((acc, _) => (acc?.price > _.price ? _ : acc), {
          price: Infinity,
          pair: [],
          size: 0,
        });
    })
);

export const getPriceForPacket = createSelector(
  productItemsFromCustomPacketSelector,
  (items) =>
    items.reduce((acc, it) => (it.price !== Infinity ? it.price : 0) + acc, 0)
);

export const transformToPacketItemsSelector = createSelector(
  productItemsFromCustomPacketSelector,
  (pairProducts) =>
    pairProducts
      .flatMap((_) => _.pair)
      .reduce<IPacketItem[]>((acc, item) => {
        const foundIndex = acc.findIndex((_) => _._product === item._id);
        if (foundIndex > -1) {
          acc[foundIndex].quantity += 1;
          return acc;
        }

        return acc.concat({
          _product: item._id,
          _productType: item.typeId,
          _productSubType: item.subTypeId,
          quantity: 1,
        });
      }, [])
);

export const CustomPacketSlice = createSlice({
  name: "customPacket",
  initialState,
  reducers: {
    updatePacket: (state, action: PayloadAction<CustomPacketItems>) => {
      state.packet.items = action.payload;
    },
    upsertPacketItem: (state, { payload }: PayloadAction<CustomPacketItem>) => {
      const isPacketItemExist = state.packet.items.find(
        (x) => x._productSubType === payload._productSubType
      );

      if (!payload.userSelectedQuantity) {
        if (isPacketItemExist)
          return {
            ...state,
            packet: {
              items: state.packet.items.filter(
                (x) => x._productSubType !== payload._productSubType
              ),
            },
          };

        return state;
      }

      if (isPacketItemExist) {
        const items = state.packet.items.map((x) =>
          x._productSubType === payload._productSubType ? payload : x
        );
        return { ...state, packet: { items } };
      }

      state.packet.items.push(payload);
    },
    removeItemsByTypeId: (state, action: PayloadAction<string>) => {
      const items = state.packet.items.filter(
        (item) => item._productType !== action.payload
      );

      state.packet.items = items;
    },
    toggleCustomPacketDrawer: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
    toggleCustomizeModalOpen: (
      state,
      {
        payload,
      }: PayloadAction<{
        packet: IPacket;
        action: ICustomPacketStore["action"];
      }>
    ) => {
      state.isCustomizeModalOpen = true;
      state.selectedPacket = payload.packet;
      state.packet.items = payload.packet.summary.map((x) => ({
        ...x,
        userSelectedQuantity: x.amount,
      }));

      state.action = payload.action;
    },
    toggleCustomizeModalClose: (state) => {
      state.isCustomizeModalOpen = false;
      state.selectedPacket = undefined;
      state.packet.items = [];
    },
  },
});

export default CustomPacketSlice.reducer;

export const {
  updatePacket,
  upsertPacketItem,
  removeItemsByTypeId,
  toggleCustomPacketDrawer,
  toggleCustomizeModalClose,
  toggleCustomizeModalOpen,
} = CustomPacketSlice.actions;
