import { LegacyApiResponse } from "src/interfaces/API";

import { BaseAuthorizedAPI } from "./config";
import { nop } from "../../utils";
import { closeAndClear } from "../slices/TransactionModalSlice";

export const CreditCard = BaseAuthorizedAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getCreditCards: builder.query<ICard[], void>({
      providesTags: ["Cards", "Account"],
      query: () => ({
        url: "/private/user/cards/read",
        method: "POST",
      }),
      transformResponse: (response: LegacyApiResponse<ICard[]>) =>
        response.documents,
    }),
    createCreditCard: builder.mutation<any, ICardRequest>({
      query: (data: ICardRequest) => ({
        url: "/private/user/cards/create-one",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["Cards"],
    }),
    createCreditCardWith3DSecure: builder.mutation<any, ICardRequest>({
      query: (data: ICardRequest) => ({
        url: "/private/user/cards/3ds/create-one-request",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["Cards"],
    }),
    selectPrimaryCard: builder.mutation<void, string>({
      query: (id: string) => ({
        url: "/private/user/cards/make-one-primary-by-id",
        method: "POST",
        body: { _id: id },
      }),
      invalidatesTags: ["Cards"],
    }),
    deleteCreditCard: builder.mutation<void, string>({
      query: (id: string) => ({
        url: "/private/user/cards/delete-one-by-id",
        method: "POST",
        body: { _id: id },
      }),
      invalidatesTags: ["Cards"],
    }),
    check3DStatusForCreateCard: builder.mutation<{ status: string }, string>({
      query: (transactionId: string) => ({
        url: `/private/user/cards/3ds/create-one-response/status/${transactionId}`,
        method: "POST",
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled
          .then((res) => {
            if (res.data.status === "OK" || res.data.status === "FAIL") {
              if (res.data.status === "OK")
                api.dispatch(BaseAuthorizedAPI.util.invalidateTags(["Cards"]));
              api.dispatch(closeAndClear());
              return res.data.status;
            }
          })
          .catch(nop);
      },
    }),
    check3DStatusForBuyRequest: builder.mutation<
      { status: string; _order: string },
      string
    >({
      query: (transactionId: string) => ({
        url: `/private/user/shopping/3ds/buy-response/status/${transactionId}`,
        method: "POST",
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled
          .then((res) => {
            if (res.data.status === "OK" || res.data.status === "FAIL") {
              if (res.data.status === "OK") {
                api.dispatch(
                  BaseAuthorizedAPI.util.invalidateTags(["Orders", "Cards"])
                );
              }
              api.dispatch(closeAndClear());

              return res.data.status;
            }
          })
          .catch(nop);
      },
    }),
  }),
});

export const {
  useGetCreditCardsQuery,
  useCreateCreditCardMutation,
  useCreateCreditCardWith3DSecureMutation,
  useSelectPrimaryCardMutation,
  useDeleteCreditCardMutation,
  useCheck3DStatusForCreateCardMutation,
  useCheck3DStatusForBuyRequestMutation,
} = CreditCard;
