export const BACKEND_API_BASE_URL =
  process.env.NEXT_PUBLIC_BASE_API ?? "https://staging-api.beije.co";

export const NEXT_PUBLIC_GTM_ID =
  process.env.NEXT_PUBLIC_GTM_ID ?? "GTM-NS83HST";

export const SEGMENT_CDN_URL =
  process.env.NEXT_PUBLIC_SEGMENT_CDN_URL ?? "https://cdn.segment.com";
export const SEGMENT_WRITE_KEY =
  process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY ??
  "24825XYzHLZHZNmoNw6wVMZOmxAGpJQH";

export const NEXT_PUBLIC_GA_MEASUREMENT_ID =
  process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID ?? "G-HM1H6YMBN7";

export const NODE_ENV = process.env.NODE_ENV ?? "development";

export const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  "https://09f6ef3b2d5943a091019b8cea979a17@o1190684.ingest.sentry.io/4503902432329728";
export const SENTRY_ENV = (process.env.SENTRY_ENV ||
  process.env.NEXT_PUBLIC_SENTRY_ENV ||
  "development") as "development" | "staging" | "production";

export const IS_PRODUCTION = SENTRY_ENV === "production";

export const SENTRY_TRACES_SAMPLE_RATE = IS_PRODUCTION ? 0.001 : 1.0;
