import { useCallback } from "react";

import { useGetSortedPacketableProductTypeQuery } from "src/store/apis";
import { SubscriptionDetail } from "src/types/Account";

export function useFilterNonPacketsFromSubscription() {
  const {
    data: packetableProductType,
    isSuccess,
    isError,
    isLoading,
    isFetching,
  } = useGetSortedPacketableProductTypeQuery();

  const getProductsFromSubscription = useCallback(
    (subscription: SubscriptionDetail) => {
      if (!packetableProductType || isLoading || !isSuccess) return [];

      return subscription.products.filter((product) => {
        const isNotPacketableProduct = packetableProductType.every(
          (packet) => packet._productType !== product.product._productType
        );
        return !!isNotPacketableProduct;
      });
    },
    [isLoading, isSuccess, packetableProductType]
  );

  return {
    getProductsFromSubscription,
    isLoading,
    isSuccess,
    isError,
    isFetching,
  };
}
