import {
  Calendar,
  CreditCard02,
  Globe05,
  Lock01,
  Mail04,
  Package,
  Ticket01,
  User01,
} from "@beije/react-icons";

export const MENU_ITEMS = [
  {
    name: "my-subscription",
    route: "/account",
    icon: Calendar,
  },
  {
    name: "my-subscription",
    route: "/account/subscription",
    isNested: true,
  },
  {
    name: "my-orders",
    route: "/account/orders",
    icon: Package,
  },
  {
    name: "our-family-code",
    route: "/account/family-code",
    icon: Ticket01,
  },
  {
    name: "divider",
    route: "",
  },
  {
    name: "my-user-info",
    route: "/account/profile",
    icon: User01,
  },
  {
    name: "change-my-password",
    route: "/account/change-password",
    icon: Lock01,
  },
  {
    name: "my-addresses",
    route: "/account/addresses",
    icon: Globe05,
  },
  {
    name: "my-saved-cards",
    route: "/account/cards",
    icon: CreditCard02,
  },

  {
    name: "communication",
    route: "/account/communication",
    icon: Mail04,
  },
  {
    name: "divider",
    route: "",
  },
];
export type MenuItems = typeof MENU_ITEMS;
