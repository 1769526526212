import {
  AnalyticsBrowser,
  Plugin as AnalyticsPlugin,
  Context,
} from "@segment/analytics-next";
import { getCookie } from "cookies-next";
import { useRouter } from "next/router";
import { event, pageView } from "nextjs-google-analytics";
import {
  PropsWithChildren,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { usePacketName } from "src/components/Packet/usePacketName";
import {
  SEGMENT_CDN_URL,
  SEGMENT_WRITE_KEY,
  ANALYTICS_EVENT_NAMES,
  GOOGLE_EVENT_NAMES,
} from "src/constant";

import {
  extractCartItemProperties,
  extractPacketProperties,
} from "src/core/analytics/utils";
import type { RootState } from "src/store";
import { orderSelector } from "src/store/slices";
import { CartItem, cartPrice, PurchaseType } from "src/store/slices/Cart";

import { AnalyticsContext } from "./analytics-context";

export const AnalyticsProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { getPacketName } = usePacketName();
  const { account } = useSelector((state: RootState) => state.AuthSlice);
  const cartItems = useSelector((state: RootState) => state.CartSlice?.items);
  const checkoutOrder = useSelector(orderSelector);
  const calculateCartPrice = useSelector((state: RootState) =>
    cartPrice(state.CartSlice)
  );

  // Load segment analytics library
  const cdnURL = SEGMENT_CDN_URL;
  const writeKey = SEGMENT_WRITE_KEY;
  const analytics = useMemo(() => {
    let gtagSessionInfo:
      | {
          sessionId: string;
          sessionNumber: string;
        }
      | undefined = undefined;

    const getGaSessionInfo = async () => {
      const gtag = (window as any).gtag;
      if (gtagSessionInfo) {
        return gtagSessionInfo;
      }
      if (typeof gtag === "function" && !gtagSessionInfo) {
        const gtagInfoPromise = Promise.all([
          new Promise<string>((resolve) => {
            gtag("get", "G-xxxxxxxxxx", "session_id", resolve);
          }),
          new Promise<string>((resolve) => {
            gtag("get", "G-xxxxxxxxxx", "session_number", resolve);
          }),
        ]).then(function ([sessionId, sessionNumber]) {
          return { sessionId, sessionNumber };
        });
        const timeoutPromise = new Promise<undefined>((resolve) => {
          setTimeout(() => {
            resolve(undefined);
          }, 1000);
        });

        gtagSessionInfo = await Promise.race([gtagInfoPromise, timeoutPromise]);
      }
    };

    const enrichAnalyticsContext = async (ctx: Context) => {
      const gaId = getCookie("_ga");
      const fbpId = getCookie("_fbp");
      if (gaId) ctx.updateEvent("context._gaId", gaId);
      if (fbpId) ctx.updateEvent("context._fbpId", fbpId);
      const gaSessionInfo = await getGaSessionInfo();
      if (gaSessionInfo) {
        ctx.updateEvent("context._gaSessionId", gaSessionInfo.sessionId);
        ctx.updateEvent(
          "context._gaSessionNumber",
          gaSessionInfo.sessionNumber
        );
      }

      return ctx;
    };

    const anonymousIdsAttacher: AnalyticsPlugin = {
      name: "anonymous-ids-attacher",
      version: "1.0.0",
      type: "enrichment",
      load: () => Promise.resolve(),
      isLoaded: () => true,
      track: enrichAnalyticsContext,
      page: enrichAnalyticsContext,
      identify: enrichAnalyticsContext,
    };

    return AnalyticsBrowser.load({
      writeKey,
      cdnURL,
      plugins: [anonymousIdsAttacher],
    });
  }, [writeKey, cdnURL]);

  // Save analytics instance to redux store
  // so that it can be used in other components
  const dispatch = useDispatch();
  useEffect(() => {
    (window as any).analyticsBrowser = analytics;
  }, [analytics, dispatch]);

  // Track page views
  const router = useRouter();
  useEffect(() => {
    analytics.page();
  }, [analytics, router.asPath]);

  // set google user config
  const setUserConfig = useCallback(() => {
    if (account?._id) {
      pageView({ userId: account?._id });
    } else {
      pageView();
    }
  }, [account?._id]);
  useEffect(() => {
    setUserConfig();
  }, [setUserConfig]);

  // Custom trackers
  const getCartItemName = useCallback(
    (cartItem: CartItem) =>
      cartItem.type === "packet" || cartItem.type === "custompacket"
        ? getPacketName(cartItem.data.items)
        : cartItem.data.name.en,
    [getPacketName]
  );

  const findItemInCart = useCallback(
    (cartItemId: string) =>
      cartItems.find((item) => item.data._id === cartItemId),
    [cartItems]
  );

  const products = useCallback(
    (analyticsType: "SEGMENT" | "GOOGLE_ANALYTICS") =>
      cartItems.map((item) => {
        return extractCartItemProperties(
          item,
          getCartItemName(item),
          analyticsType
        );
      }),
    [cartItems, getCartItemName]
  );

  const trackCartView = useCallback(() => {
    analytics.track(ANALYTICS_EVENT_NAMES.CART_VIEWED, {
      revenue: calculateCartPrice.total,
      currency: "TRY",
      products: products("SEGMENT"),
    });
    event(GOOGLE_EVENT_NAMES.CART_VIEWED, {
      value: Number(calculateCartPrice.total),
      currency: "TRY",
      items: products("GOOGLE_ANALYTICS"),
    });
  }, [calculateCartPrice, analytics, products]);

  const trackAddAddress = useCallback(() => {
    analytics.track(ANALYTICS_EVENT_NAMES.ADD_ADDRESS_INFO, {
      value: checkoutOrder?.price.grossAmount,
      revenue: checkoutOrder?.price.chargedAmount,
      currency: "TRY",
      products: products("SEGMENT"),
    });
    event(GOOGLE_EVENT_NAMES.ADD_ADDRESS_INFO, {
      value: Number(checkoutOrder?.price.chargedAmount),
      currency: "TRY",
      items: products("GOOGLE_ANALYTICS"),
    });
  }, [checkoutOrder, analytics, products]);

  const trackAddPaymentInfo = useCallback(() => {
    analytics.track(ANALYTICS_EVENT_NAMES.ADD_PAYMENT_INFO, {
      value: checkoutOrder?.price.grossAmount,
      revenue: checkoutOrder?.price.chargedAmount,
      currency: "TRY",
      products: products("SEGMENT"),
    });
    event(GOOGLE_EVENT_NAMES.ADD_PAYMENT_INFO, {
      value: Number(checkoutOrder?.price.chargedAmount),
      currency: "TRY",
      items: products("GOOGLE_ANALYTICS"),
    });
  }, [checkoutOrder, analytics, products]);

  const trackInitiateCheckout = useCallback(() => {
    analytics.track(ANALYTICS_EVENT_NAMES.INITIATE_CHECKOUT, {
      value: checkoutOrder?.price.grossAmount,
      revenue: checkoutOrder?.price.chargedAmount,
      currency: "TRY",
      products: products("SEGMENT"),
    });
    event(GOOGLE_EVENT_NAMES.INITIATE_CHECKOUT, {
      value: Number(checkoutOrder?.price.chargedAmount),
      currency: "TRY",
      items: products("GOOGLE_ANALYTICS"),
    });
  }, [checkoutOrder, analytics, products]);

  const [cloneCartItems, setCloneCartItems] = useState<any[]>([]);
  useEffect(() => {
    if (cartItems.length) setCloneCartItems(cartItems);
  }, [cartItems]);

  const trackPurchase = useCallback(
    (order_id?: string, revenue?: number) => {
      analytics.track(ANALYTICS_EVENT_NAMES.PURCHASE, {
        email: account?.email,
        order_id,
        revenue,
        currency: "TRY",
        products: cloneCartItems.map((item) =>
          extractCartItemProperties(item, getCartItemName(item), "SEGMENT")
        ),
      });
      event(GOOGLE_EVENT_NAMES.PURCHASE, {
        email: account?.email,
        value: Number(revenue),
        transaction_id: order_id,
        currency: "TRY",
        items: cloneCartItems.map((item) =>
          extractCartItemProperties(
            item,
            getCartItemName(item),
            "GOOGLE_ANALYTICS"
          )
        ),
      });
    },
    [analytics, cloneCartItems, account?.email, getCartItemName]
  );

  const trackPacketView = useCallback(
    (packet: IPacket) => {
      analytics.track(ANALYTICS_EVENT_NAMES.PRODUCT_VIEW, {
        ...extractPacketProperties(
          packet,
          getPacketName(packet.items),
          "SEGMENT",
          "packet"
        ),
        currency: "TRY",
      });
      event(GOOGLE_EVENT_NAMES.PRODUCT_VIEW, {
        currency: "TRY",
        items: [
          extractPacketProperties(
            packet,
            getPacketName(packet.items),
            "GOOGLE_ANALYTICS",
            "packet"
          ),
        ],
      });
    },
    [analytics, getPacketName]
  );

  const trackPacketFilter = useCallback(
    (url: string, filters: { type: string; value: string }[]) => {
      analytics.track(ANALYTICS_EVENT_NAMES.PRODUCT_LIST_FILTERED, {
        list_id: url,
        filters,
      });
    },
    [analytics]
  );

  const trackProductView = useCallback(
    (productType: IProductType) => {
      analytics.track(ANALYTICS_EVENT_NAMES.PRODUCT_VIEW, {
        name: productType.name.en,
        product_id: productType._id,
        price: productType.minimumPrice,
        product_type: "product",
        sku: productType.pageIdentifier,
        url: `${window.location.origin}/product/${productType.pageIdentifier}`,
        currency: "TRY",
      });
      event(GOOGLE_EVENT_NAMES.PRODUCT_VIEW, {
        currency: "TRY",
        items: [
          {
            item_name: productType.name.en,
            item_id: productType._id,
            price: productType.minimumPrice,
            product_type: "product",
            sku: productType.pageIdentifier,
            url: `${window.location.origin}/product/${productType.pageIdentifier}`,
          },
        ],
      });
    },
    [analytics]
  );

  const trackAddToCart = useCallback(
    (cartItem: FixMyType) => {
      analytics.track(ANALYTICS_EVENT_NAMES.PRODUCT_ADDED, {
        ...extractCartItemProperties(
          cartItem,
          getCartItemName(cartItem),
          "SEGMENT"
        ),
        currency: "TRY",
      });
      event(GOOGLE_EVENT_NAMES.PRODUCT_ADDED, {
        currency: "TRY",
        items: [
          extractCartItemProperties(
            cartItem,
            getCartItemName(cartItem),
            "GOOGLE_ANALYTICS"
          ),
        ],
      });
    },
    [analytics, getCartItemName]
  );

  const trackRemoveFromCart = useCallback(
    (cartItemId: string) => {
      const cartItem = findItemInCart(cartItemId);
      if (cartItem) {
        analytics.track(ANALYTICS_EVENT_NAMES.PRODUCT_REMOVED, {
          ...extractCartItemProperties(
            cartItem,
            getCartItemName(cartItem),
            "SEGMENT"
          ),
          currency: "TRY",
        });

        event(GOOGLE_EVENT_NAMES.PRODUCT_REMOVED, {
          currency: "TRY",
          item: extractCartItemProperties(
            cartItem,
            getCartItemName(cartItem),
            "GOOGLE_ANALYTICS"
          ),
        });
      }
    },
    [analytics, getCartItemName, findItemInCart]
  );

  const trackChangePaymentOption = useCallback(
    (cartItemId: string, purchaseType: PurchaseType) => {
      const cartItem = findItemInCart(cartItemId);
      if (cartItem) {
        trackRemoveFromCart(cartItem.data._id);
        trackAddToCart({ ...cartItem, purchaseType });
      }
    },
    [findItemInCart, trackRemoveFromCart, trackAddToCart]
  );

  const trackChangeCartItemQuantity = useCallback(
    (cartItemId: string, type: "DECREASE" | "INCREASE") => {
      const cartItem = findItemInCart(cartItemId);
      if (cartItem) {
        const quantity =
          type === "DECREASE" ? cartItem.quantity - 1 : cartItem.quantity + 1;
        trackRemoveFromCart(cartItem.data._id);
        trackAddToCart({ ...cartItem, quantity });
      }
    },
    [findItemInCart, trackRemoveFromCart, trackAddToCart]
  );

  const trackUserAuth = useCallback(
    ({ account }: IAccount, type: string) => {
      const userInfo = {
        birthday: account?.dateOfBirth,
        createdAt: account?.createdAt,
        email: account?.email,
        firstName: account?.firstName,
        lastLogin: account?.auth.lastSuccessfulLogin,
        lastName: account?.lastName,
        phone: `${account?.phone?.dialCode}${account?.phone?.number}`,
      };

      analytics.identify(account?._id, userInfo);
      analytics.track(type, {
        method: "email",
        ...userInfo,
      });
      event(type, {
        currency: "TRY",
        method: "email",
        ...userInfo,
      });
    },
    [analytics]
  );

  const trackUserLoggedIn = useCallback(
    (userData: IAccount) => {
      trackUserAuth(userData, ANALYTICS_EVENT_NAMES.USER_LOGGED_IN);
    },
    [trackUserAuth]
  );
  const trackUserSignedUp = useCallback(
    (userData: IAccount) => {
      trackUserAuth(userData, ANALYTICS_EVENT_NAMES.USER_SIGNED_UP);
    },
    [trackUserAuth]
  );

  const trackuserLoggedOut = useCallback(() => {
    analytics.track(ANALYTICS_EVENT_NAMES.USER_LOGGED_OUT);
  }, [analytics]);

  const customTrackers = {
    trackCartView,
    trackAddAddress,
    trackAddPaymentInfo,
    trackInitiateCheckout,
    trackPurchase,
    trackPacketView,
    trackProductView,
    trackAddToCart,
    trackChangePaymentOption,
    trackChangeCartItemQuantity,
    trackRemoveFromCart,
    trackUserLoggedIn,
    trackUserSignedUp,
    trackuserLoggedOut,
    trackPacketFilter,
  };

  return (
    <AnalyticsContext.Provider
      value={{ analytics, writeKey, cdnURL, customTrackers }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
