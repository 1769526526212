import { createRemoteJWKSet, jwtVerify } from "jose";

import { BACKEND_API_BASE_URL } from "src/constant";

const JWKS = createRemoteJWKSet(
  new URL(`/public/auth/user-jwks`, BACKEND_API_BASE_URL)
);

export const verifyToken = async (jwt?: string) => {
  if (!jwt) return false;

  try {
    const { payload } = await jwtVerify(jwt, JWKS);

    return payload;
  } catch (error) {
    return false;
  }
};
