import { useMemo } from "react";

export const useBaseUrl = () => {
  return useMemo(() => {
    if (typeof window !== "undefined") {
      return window.location.origin;
    }
    return "https://beije.co";
  }, []);
};
