// OrderDateModal
import { createSlice } from "@reduxjs/toolkit";

type OrderDateModalState = {
  isOpen: boolean;
};

const initialState: OrderDateModalState = {
  isOpen: false,
};

export const ChangeOrderDateModalSlice = createSlice({
  name: "confirmationModal",
  initialState,
  reducers: {
    toggleOrderDateOpen: (state) => {
      state.isOpen = true;
    },
    toggleOrderDateClose: (state) => {
      state.isOpen = false;
    },
  },
});

export const { toggleOrderDateOpen, toggleOrderDateClose } =
  ChangeOrderDateModalSlice.actions;
