import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useHasDuePaymentMutation } from "src/store/apis";

import { createSnackbarErrorHandler } from "../errorHandling";

export function useHasDuePayment(id: string) {
  const [hasDyePaymentStatus, setHasDyePaymentStatus] = useState(false);
  const dispatch = useDispatch();

  const [hasDoPaymentRequest, { isError, isLoading, isSuccess }] =
    useHasDuePaymentMutation();

  useEffect(() => {
    hasDoPaymentRequest()
      .unwrap()
      .then((data) => {
        setHasDyePaymentStatus(data.has);
      })
      .catch(createSnackbarErrorHandler(dispatch));
  }, [id, hasDoPaymentRequest]);

  return { hasDyePaymentStatus, isLoading, isError, isSuccess };
}
