import type { LegacyApiResponse, OrderStatus } from "src/interfaces/API";
import {
  DistanceSalesContract,
  SimulateRequestProductListObject,
} from "src/interfaces/API/SimulateDtos";

import { createSnackbarErrorHandler, nop } from "src/utils";

import { BaseAuthorizedAPI, BaseNextAPI } from "./config";
import { setDistanceSaleContract } from "../slices";
import { upsertCredentials, updateUserBalance } from "../slices/Auth";

export const PAUSE_ACTION = {
  APPLY_TO_CURRENT_ORDER: "APPLY_TO_CURRENT_ORDER",
  APPLY_TO_NEXT_ORDER: "APPLY_TO_NEXT_ORDER",
  DELETE_DUE_PAYMENTS: "DELETE_DUE_PAYMENTS",
  PAY_DUE_PAYMENTS: "PAY_DUE_PAYMENTS",
} as const;
export type PauseAction = keyof typeof PAUSE_ACTION;

export const Account = BaseAuthorizedAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    userDetails: builder.query<IUserDetails, string>({
      query: (id) => `/private/user/users/${id}`,
      onQueryStarted: async (_, api) => {
        api.queryFulfilled
          .then((res) => {
            const { token, document } = res.data;
            api.dispatch(upsertCredentials({ account: document, token }));
          })
          .catch(createSnackbarErrorHandler(api.dispatch));
      },
    }),
    userPromoCode: builder.query<IPromoCode, void>({
      query: () => "/private/user/promo-code",
      providesTags: ["Account"],
    }),
    userPromoCodeHistory: builder.query<IPromoCodeHistory[], void>({
      query: () => "/private/user/promo-code/usage-history",
      providesTags: ["Account"],
    }),
    updateUser: builder.mutation<IUserDetails, unknown>({
      query: ({ data, version }) => ({
        url: `/private/user/users/update-account?version=${version}`,
        method: "post",
        body: data,
      }),
      onQueryStarted: async (_, api) => {
        api.queryFulfilled
          .then(({ data }) => {
            api.dispatch(
              upsertCredentials({
                account: data.document,
                token: data.token,
              })
            );
          })
          .catch(nop);
      },
      invalidatesTags: ["Account"],
    }),
    deleteUser: builder.mutation<number, unknown>({
      query: ({ reason, version }) => ({
        url: `/private/user/users/delete-account?version=${version}`,
        method: "post",
        body: { ...reason },
      }),
      invalidatesTags: ["Account"],
    }),
    changePassword: builder.mutation<IAccount, IChangePassword>({
      query: (data: IChangePassword) => ({
        url: "/private/user/users/change-password",
        method: "post",
        body: { ...data },
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled
          .then(({ data }) => {
            api.dispatch(upsertCredentials(data));
          })
          .catch(nop);
      },
    }),
    getSubscriptions: builder.query<ISubscriptionDetail[], void>({
      query: () => "/private/user/subscriptions?query={}",
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ _id: id }) => ({ type: "Subscription", id } as const)
              ),
              { type: "Subscription", id: "LIST" },
              "Account",
            ]
          : [{ type: "Subscription", id: "LIST" }, "Account"],
      transformResponse: (res: ISubscription) => res.documents,
    }),
    getSubscriptionByID: builder.query<ISubscriptionDetail, string>({
      query: (id) => `/private/user/subscriptions/${id}`,
      transformResponse: (res: {
        document: ISubscriptionDetail;
        token: string;
      }) => res.document,
      providesTags: (result, error, id) => [
        { type: "Subscription", id },
        "Account",
      ],
    }),
    getDeletionReasons: builder.query<IAccountDeletionReason[], void>({
      query: () => "/private/user/account-deletion-reasons?query={}",
      transformResponse: (res: IAccountDeletionReasons) => res.documents || [],
    }),
    bookSupportCall: builder.mutation<
      void,
      { timeRange: { start: string; end: string } }
    >({
      query: (data: { timeRange: { start: string; end: string } }) => ({
        url: "/private/user/call-requests/send",
        method: "POST",
        body: { ...data },
      }),
    }),
    hasDuePayment: builder.mutation<{ token: string; has: boolean }, void>({
      query: () => ({
        url: "/private/user/due-payments/has-active",
        method: "POST",
      }),
      invalidatesTags: ["DuePayment"],
    }),
    getDuePaymentIds: builder.query<string[], void>({
      query: () => `/private/user/due-payments/?query={}`,
      providesTags: ["Account", "DuePayment"],
      transformResponse: (res: LegacyApiResponse<{ _id: string }[]>) =>
        res.documents.map((x) => x._id),
    }),
    payDuePayments: builder.mutation<unknown, string[]>({
      query: (ids) => ({
        url: `/private/user/due-payments/pay`,
        method: "post",
        body: { _ids: ids },
      }),
      invalidatesTags: ["DuePayment"],
    }),
    pauseSubscription: builder.mutation<
      unknown,
      {
        subscriptionId: string;
        action?: PauseAction;
        reason: string;
        duration: number;
      }
    >({
      query: ({ subscriptionId, action, duration, reason }) => ({
        url: `/private/user/subscriptions/${subscriptionId}/skip`,
        method: "POST",
        body: {
          action: action ?? null,
          skipAmount: duration,
          skipReasons: [
            {
              date: new Date().toISOString(),
              reason,
            },
          ],
        },
      }),
      invalidatesTags: (res, err, { subscriptionId: id }) => [
        { type: "Subscription", id },
      ],
    }),
    unPauseSubscription: builder.mutation<unknown, { subscriptionId: string }>({
      query: ({ subscriptionId }) => ({
        url: `/private/user/subscriptions/${subscriptionId}/unskip`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: (res, err, { subscriptionId: id }) => [
        { type: "Subscription", id },
      ],
    }),
    changeOrderDate: builder.mutation<
      unknown,
      { recurrenceDate: Date; action?: string | null }
    >({
      query: ({ action, recurrenceDate }) => ({
        url: `/private/user/subscriptions/change-recurrence-date`,
        method: "POST",
        body: {
          recurrenceDate: recurrenceDate.toISOString(),
          action: action ?? null,
        },
      }),
      invalidatesTags: [{ type: "Subscription", id: "LIST" }, "Orders"],
    }),
    getSubscriptionLastOrderStatus: builder.mutation<
      { status: OrderStatus },
      { subscriptionId: string }
    >({
      query: ({ subscriptionId }) => ({
        url: `/private/user/subscriptions/${subscriptionId}/get-last-order-status`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { subscriptionId: id }) => [
        { type: "Subscription", id },
      ],
    }),
    renameSubscriptionName: builder.mutation<
      unknown,
      { subscriptionId: string; name: string }
    >({
      query: ({ subscriptionId, ...body }) => ({
        url: `/private/user/subscriptions/${subscriptionId}/rename`,
        method: "post",
        body,
      }),
      invalidatesTags: (result, error, { subscriptionId: id }) => [
        { type: "Subscription", id },
      ],
    }),
    editSubscription: builder.mutation<
      unknown,
      {
        subscriptionId: string;
        action?: PauseAction;
        subscriptionDetail: SimulateRequestProductListObject;
      }
    >({
      query: ({ subscriptionId, action, subscriptionDetail }) => ({
        url: `/private/user/subscriptions/${subscriptionId}/edit`,
        method: "POST",
        body: {
          action,
          ...subscriptionDetail,
        },
      }),
      invalidatesTags: (result, error, { subscriptionId: id }) => [
        { type: "Subscription", id },
      ],
      onQueryStarted: (_, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then(() => {
            dispatch(BaseAuthorizedAPI.util.invalidateTags(["Subscription"]));
          })
          .catch(nop);
      },
    }),
    refundOrder: builder.mutation<
      unknown,
      {
        orderId: string;
        body: {
          products: {
            _id: string;
            quantity: number;
            _reason: string;
            note: string;
          }[];
        };
      }
    >({
      query: ({ orderId, body }) => ({
        url: `/private/user/refund/${orderId}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Orders"],
      onQueryStarted: (_, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then(() => {
            dispatch(BaseNextAPI.util.invalidateTags(["Order"]));
          })
          .catch(nop);
      },
    }),
    accountBalance: builder.mutation<
      {
        token: string;
        balance: number;
      },
      void
    >({
      query: () => ({
        url: "/private/user/users/account-balance",
        method: "post",
      }),
      onQueryStarted(_, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then((res) => {
            dispatch(updateUserBalance(res.data.balance));
          })
          .catch(nop);
      },
    }),
    orderImmediatelySubscriptions: builder.mutation<void, string[]>({
      query: (subscriptions) => ({
        url: "/private/user/subscriptions/order-immediately",
        method: "post",
        body: { _subscriptions: subscriptions },
      }),
      invalidatesTags: ["Orders"],
      onQueryStarted(_, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then(() => {
            dispatch(BaseNextAPI.util.invalidateTags(["Order"]));
          })
          .catch(nop);
      },
    }),
    getDistanceSalesContractById: builder.mutation<
      {
        token: string;
        distanceSaleContract: DistanceSalesContract;
      },
      string
    >({
      query: (contractId) => ({
        url: `/private/user/users/getDistanceSalesContract/${contractId}`,
        method: "post",
      }),
      onQueryStarted(_, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then((data) => {
            dispatch(setDistanceSaleContract(data.data.distanceSaleContract));
          })
          .catch(nop);
      },
    }),
    cancelSubscription: builder.mutation<
      unknown,
      { subscriptionId: string; _reason: string; note?: string }
    >({
      query: ({ subscriptionId, _reason, note }) => ({
        url: `/private/user/subscriptions/${subscriptionId}/cancel`,
        method: "POST",
        body: {
          _reason,
          note,
        },
      }),
      onQueryStarted(_, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then(() => {
            dispatch(BaseNextAPI.util.invalidateTags(["Order"]));
          })
          .catch(nop);
      },
      invalidatesTags: (res, err, { subscriptionId: id }) => [
        { type: "Subscription", id },
      ],
    }),
  }),
});

export const {
  useUserDetailsQuery,
  useUserPromoCodeQuery,
  useUserPromoCodeHistoryQuery,
  useGetSubscriptionsQuery,
  useGetSubscriptionByIDQuery,
  useLazyGetSubscriptionByIDQuery,
  useGetDeletionReasonsQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useChangePasswordMutation,
  useBookSupportCallMutation,
  useHasDuePaymentMutation,
  usePauseSubscriptionMutation,
  useUnPauseSubscriptionMutation,
  useGetSubscriptionLastOrderStatusMutation,
  useChangeOrderDateMutation,
  useRenameSubscriptionNameMutation,
  useEditSubscriptionMutation,
  useRefundOrderMutation,
  useAccountBalanceMutation,
  useGetDuePaymentIdsQuery,
  useLazyGetDuePaymentIdsQuery,
  usePayDuePaymentsMutation,
  useOrderImmediatelySubscriptionsMutation,
  useGetDistanceSalesContractByIdMutation,
  useCancelSubscriptionMutation,
} = Account;
