import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { ShipmentInfo } from "src/interfaces/API";

type ShipmentState = {
  isOpen: boolean;
  shipment: ShipmentInfo | null;
};

const initialState: ShipmentState = {
  isOpen: false,
  shipment: null,
};

export const CargoTrackSlice = createSlice({
  name: "cargoTrack",
  initialState,
  reducers: {
    toggleCargoTrackModal: (
      state,
      { payload }: PayloadAction<ShipmentState["shipment"] | undefined>
    ) => {
      state.isOpen = !state.isOpen;
      state.shipment = payload || null;
    },
  },
});

export const { toggleCargoTrackModal } = CargoTrackSlice.actions;
