import {
  TypedStartListening,
  TypedAddListener,
  createListenerMiddleware,
  addListener,
} from "@reduxjs/toolkit";
import * as Sentry from "@sentry/nextjs";

import { BaseAuthorizedAPI, BaseNextAPI } from "./apis/config";

import { logout, upsertCredentials } from "./slices";

export const listenerMiddleware = createListenerMiddleware();

import type { RootState, AppDispatch } from "./store";

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>;

// TODO: if the file got too big, move listeners of each slice to its own slice file
// https://redux-toolkit.js.org/api/createListenerMiddleware#organizing-listeners-in-files

startAppListening({
  actionCreator: logout,
  effect: async () => {
    Sentry.setUser(null);
  },
});

startAppListening({
  actionCreator: upsertCredentials,
  effect: async ({ payload }, listenerApi) => {
    if (payload.token && payload.account) {
      Sentry.setUser({
        email: payload.account.email,
        id: payload.account._id,
      });
    }

    listenerApi.dispatch(BaseAuthorizedAPI.util.invalidateTags(["Account"]));
    listenerApi.dispatch(BaseNextAPI.util.invalidateTags(["Account"]));
  },
});
