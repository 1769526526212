import { TRIAL_PACKET_ID } from "./packet";

export const TRIAL_PRODUCT_PAGE_LINK = "/product/trial";

export const PURCHASABLE_ITEMS_ID = [
  TRIAL_PACKET_ID,
  "602adbf49b79ea1fc47fd36d",
  "611108076cdc4b1aa1a6c2d9",
  "61274291447ff844f3cc4437",
];
