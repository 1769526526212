import { AnalyticsBrowser } from "@segment/analytics-next";
import { createContext } from "react";

import { PurchaseType } from "src/store/slices";

export const AnalyticsContext = createContext<
  | {
      analytics: AnalyticsBrowser;
      writeKey: string;
      cdnURL: string;
      customTrackers: {
        trackCartView: () => void;
        trackAddAddress: () => void;
        trackAddPaymentInfo: () => void;
        trackInitiateCheckout: () => void;
        trackPurchase: (order_id?: string, revenue?: number) => void;
        trackPacketView: (packet: IPacket) => void;
        trackProductView: (productType: IProductType) => void;
        trackAddToCart: (cartItem: FixMyType) => void;
        trackRemoveFromCart: (cartItemId: string) => void;
        trackChangePaymentOption: (
          cartItemId: string,
          purchaseType: PurchaseType
        ) => void;
        trackChangeCartItemQuantity: (
          cartItemId: string,
          type: "DECREASE" | "INCREASE"
        ) => void;
        trackUserLoggedIn: (account: IAccount) => void;
        trackUserSignedUp: (account: IAccount) => void;
        trackuserLoggedOut: () => void;
        trackPacketFilter: (
          url: string,
          filters: { type: string; value: string }[]
        ) => void;
      };
    }
  | undefined
>(undefined);
