import { createSlice } from "@reduxjs/toolkit";

type IPacketStore = {
  popularPackets: IPacket[];
};

const initialState: IPacketStore = {
  popularPackets: [],
};

export const PacketsSlice = createSlice({
  name: "packets",
  initialState,
  reducers: {},
});
