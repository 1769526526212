import * as Sentry from "@sentry/nextjs";
import { useEffect, useMemo } from "react";

import { DONATION_PRODUCT_TYPE_ID } from "src/constant";
import {
  useGetPacketsQuery,
  usePublicPrepareCheckoutMutation,
} from "src/store/apis";
import type { CartItem } from "src/store/slices";
import { SubscriptionDetail } from "src/types/Account";

import { useFilterNonPacketsFromSubscription } from "./useFilterNonPacketsFromSubscription";
import { prepareRequestPayload } from "../checkout";
import { createFilterQuery } from "../packet";

export function useGenerateSimulateFromSubscription(
  subscription?: SubscriptionDetail
) {
  const {
    data: packets,
    isLoading: isPacketsLoading,
    isSuccess: isPacketsSuccess,
    isFetching: isPacketsFetching,
    isError: isPakcetsError,
  } = useGetPacketsQuery(
    subscription?.packets
      ? {
          filter: createFilterQuery(
            [
              {
                key: "_id",
                values: subscription.packets.map((_) => _._packet),
              },
            ],
            true
          ),
        }
      : undefined,
    {
      skip: !subscription?.packets || !subscription?.packets?.length,
    }
  );

  const {
    getProductsFromSubscription,
    isLoading: isProductsLoading,
    isFetching: isProductsFetching,
  } = useFilterNonPacketsFromSubscription();

  const [publicPrepareCheckout, { isLoading, isSuccess, data }] =
    usePublicPrepareCheckoutMutation();

  const products = useMemo(() => {
    if (!subscription) return [];
    return getProductsFromSubscription(subscription);
  }, [getProductsFromSubscription, subscription]);

  const simulateRequestBody = useMemo(() => {
    if (
      isPacketsFetching ||
      isProductsFetching ||
      !subscription ||
      isPakcetsError
    )
      return;

    try {
      const subscriptionPacketItems = subscription.packets?.map(
        ({ _packet }) => {
          const packet = packets?.data.find((p) => p._id === _packet);
          if (!packet)
            throw new Error(
              `Subscription has ${_packet} packet id that doesn't exist in packet api`
            );
          const packetCartItem: CartItem = {
            type: "packet",
            data: packet,
            isQuantifiable: false,
            purchaseType: "subscription",
            createdAt: new Date().getTime(),
            quantity: 1,
          };

          return packetCartItem;
        }
      );

      const subscriptionProductItems = products.map(({ product, quantity }) => {
        const isCharityProduct =
          product._productType === DONATION_PRODUCT_TYPE_ID;
        if (!isCharityProduct)
          throw new Error(
            `Subscription has ${product._id} product id that is not charity product type`
          );

        const charityCartItem: CartItem = {
          type: "donation",
          data: {
            picture: "",
            ...product,
            typeId: product._productType,
            subTypeId: product._productSubType,
            APIPrice: product.price,
            price: product.price.grossAmount,
          },
          isQuantifiable: true,
          purchaseType: "subscription",
          createdAt: new Date().getTime(),
          quantity,
        };
        return charityCartItem;
      });

      const subscriptionItems = [];
      if (subscriptionPacketItems) {
        subscriptionItems.push(subscriptionPacketItems);
      }
      subscriptionItems.push(subscriptionProductItems);

      return prepareRequestPayload({
        subscriptionCartItems: subscriptionItems.flat(),
        oneTimePurchaseCartItems: [],
        price: {
          cartItems: "",
          total: subscription.products
            .reduce((acc, item) => {
              return (
                (item.product.price.grossAmount?.subscription || 0) *
                  item.quantity +
                acc
              );
            }, 0)
            .toString(),
        },
      });
    } catch (error) {
      Sentry.captureException(error);
      return;
    }
  }, [
    isPacketsFetching,
    isProductsFetching,
    subscription,
    products,
    packets?.data,
  ]);

  useEffect(() => {
    if (simulateRequestBody) {
      publicPrepareCheckout(simulateRequestBody);
    }
  }, [simulateRequestBody, publicPrepareCheckout]);

  return {
    simulate: { isLoading, isSuccess, data, requestBody: simulateRequestBody },
    products: { isLoading: isProductsLoading, data: products },
    packets: {
      isLoading: isPacketsLoading,
      isSuccess: isPacketsSuccess,
      data: packets,
    },
  };
}
