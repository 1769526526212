import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AnalyticsBrowser,
  EventProperties,
  Options,
  SegmentEvent,
} from "@segment/analytics-next";

type AnalyticsSclie = {
  instance: AnalyticsBrowser | null;
};

const initialState: AnalyticsSclie = {
  instance: null,
};

export const AnalyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    track: (
      state,
      {
        payload,
      }: PayloadAction<{
        name: string | SegmentEvent;
        properties?: EventProperties;
        options?: Options;
      }>
    ) => {
      const { name, properties, options } = payload;
      (window as any)?.analyticsBrowser?.track(name, properties, options);
    },
  },
});

export const { track } = AnalyticsSlice.actions;
