import type { CustomOrder } from "src/pages/api/private/getAllOrders";

import { BaseNextAPI } from "./config";

export const OrdersNextAPI = BaseNextAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAllOrders: builder.query<
      // { token: string; orders: Order[]; normalizedOrder: CustomOrder[] },
      CustomOrder[],
      void
    >({
      query: () => ({
        url: "/private/getAllOrders",
        method: "POST",
      }),
      transformResponse: (res: FixMyType) => res.normalizedOrder,
      providesTags: ["Order", "Account"],
    }),
  }),
});

export const { useGetAllOrdersQuery } = OrdersNextAPI;
