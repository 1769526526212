export const ANALYTICS_EVENT_NAMES = {
  /**
   * https://segment.com/docs/connections/spec/ecommerce/v2/#product-list-filtered
   */
  PRODUCT_LIST_FILTERED: "Product List Filtered",

  /**
   * https://segment.com/docs/connections/spec/ecommerce/v2/#product-added
   */
  PRODUCT_ADDED: "Product Added",

  /**
   * https://segment.com/docs/connections/spec/ecommerce/v2/#product-removed
   */
  PRODUCT_REMOVED: "Product Removed",

  /**
   * https://segment.com/docs/connections/spec/ecommerce/v2/#cart-viewed
   */
  CART_VIEWED: "Cart Viewed",

  /**
   * Triggered on product and packet pages
   * @param {ProductInfo} item
   */
  PRODUCT_VIEW: "Product Viewed",

  USER_LOGGED_IN: "User Logged In",
  USER_LOGGED_OUT: "User Logged Out",
  USER_SIGNED_UP: "User Signed Up",

  /**
   * On "Proceed with payment" button clicks on drawer cart or cart page
   * @param {ProductInfo[]} items
   */
  INITIATE_CHECKOUT: "Checkout Started",

  /**
   * Triggered when customer has products its on cart and adds an address on the purchase flow
   * @param {ProductInfo[]} items
   */
  ADD_ADDRESS_INFO: "Add Address Info",

  /**
   * Triggered when customer has products its on cart and adds a payment method on the purchase flow
   * @param {ProductInfo[]} items
   */
  ADD_PAYMENT_INFO: "Add Payment Info",

  /**
   * When a purchase is successfully completed
   * @param {ProductInfo[]} items
   */
  PURCHASE: "Order Completed",
} as const;

export const GOOGLE_EVENT_NAMES = {
  /**
   * https://segment.com/docs/connections/spec/ecommerce/v2/#product-added
   */
  PRODUCT_ADDED: "add_to_cart",

  /**
   * https://segment.com/docs/connections/spec/ecommerce/v2/#product-removed
   */
  PRODUCT_REMOVED: "remove_from_cart",

  /**
   * https://segment.com/docs/connections/spec/ecommerce/v2/#cart-viewed
   */
  CART_VIEWED: "view_cart",

  /**
   * Triggered on product and packet pages
   * @param {ProductInfo} item
   */
  PRODUCT_VIEW: "view_item",

  USER_LOGGED_IN: "login",
  USER_SIGNED_UP: "register",

  /**
   * On "Proceed with payment" button clicks on drawer cart or cart page
   * @param {ProductInfo[]} items
   */
  INITIATE_CHECKOUT: "begin_checkout",

  /**
   * Triggered when customer has products its on cart and adds an address on the purchase flow
   * @param {ProductInfo[]} items
   */
  ADD_ADDRESS_INFO: "add_shipping_info",

  /**
   * Triggered when customer has products its on cart and adds a payment method on the purchase flow
   * @param {ProductInfo[]} items
   */
  ADD_PAYMENT_INFO: "add_payment_info",

  /**
   * When a purchase is successfully completed
   * @param {ProductInfo[]} items
   */
  PURCHASE: "purchase",
} as const;
