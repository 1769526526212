import { useRouter } from "next/router";
import { DefaultSeo } from "next-seo";
import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import type { LocaleName } from "src/types/i18n";

import { useBaseUrl } from "./useBaseUrl";
import { useDefaultLocale } from "../i18n/useDefaultLocale";

export const RootSeo = () => {
  const { asPath } = useRouter();
  const { t } = useTranslation();

  const baseUrl = useBaseUrl();
  const defaultLocale = useDefaultLocale();

  const languageAlternates = useMemo(() => {
    const locales: LocaleName[] = ["en", "tr"];

    const getCurrentFullUrlInLocale = (locale: LocaleName) => {
      const languagePrefix = locale === defaultLocale ? "" : `/${locale}`;
      return `${baseUrl}${languagePrefix}${asPath}`;
    };

    return locales.map((hrefLang) => ({
      hrefLang,
      href: getCurrentFullUrlInLocale(hrefLang),
    }));
  }, [asPath, baseUrl, defaultLocale]);

  return (
    <DefaultSeo
      defaultTitle="beije."
      titleTemplate={`%s | beije.`}
      description={t("common:seo.description")}
      openGraph={{
        type: "website",
        locale: "tr",
        url: baseUrl,
        siteName: "beije.",
      }}
      languageAlternates={languageAlternates}
    />
  );
};
