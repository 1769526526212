export function typeSafeObjectKeys<T>(obj: T extends object ? T : never) {
  return Object.keys(obj) as Array<keyof T>;
}

type SortFnArgs = Date | string;
export function dateAscendingSorter(a: SortFnArgs, b: SortFnArgs) {
  const first = typeof a === "string" ? new Date(a) : a;
  const second = typeof b === "string" ? new Date(b) : b;

  return first.getTime() - second.getTime();
}

export function dateDescendingSorter(a: SortFnArgs, b: SortFnArgs) {
  const first = typeof a === "string" ? new Date(a) : a;
  const second = typeof b === "string" ? new Date(b) : b;

  return second.getTime() - first.getTime();
}
