import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 552,
      md: 852,
      lg: 1152,
      xl: 1152,
    },
  },
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          overflowY: "scroll",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: ".5rem !important",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: ".5rem !important",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          backgroundColor: "#fff",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontSize: "1.25rem",
          color: "#00000099",
        },
        root: {
          padding: "1.5rem 1.5rem 0.5rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "0rem 1.5rem",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: ".5rem !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 64,
          textTransform: "none",
          fontFamily: "Gordita",
        },
        sizeLarge: {
          fontSize: "1rem",
          lineHeight: "162%",
          letterSpacing: "-0.015em",
          paddingTop: 12,
          paddingBottom: 10,
          paddingX: 16,
        },
        sizeMedium: {
          paddingTop: 10,
          paddingBottom: 8,
          paddingX: 12,
          fontSize: "0.875rem",
          lineHeight: "171%",
          letterSpacing: "-0.01em",
        },
        sizeSmall: {
          paddingTop: 6,
          paddingBottom: 4,
          paddingX: 8,
          fontSize: "0.75rem",
          lineHeight: "167%",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          padding: "0 4px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          color: "#343131",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: 0,
          backgroundColor: "transparent",
          boxShadow: "none",
          border: "none",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Gordita", "Ivy Journal"].join(","),
    allVariants: {
      fontFamily: "Gordita",
      lineHeight: "120%",
      letterSpacing: "-0.03em",
      fontStyle: "normal",
    },
    desktopDisplay: {
      fontFamily: "Gordita",
      fontWeight: "500",
      fontSize: "3rem",
      lineHeight: "120%",
    },
    desktopH1: {
      fontFamily: "Gordita",
      fontWeight: "500",
      fontSize: "2.5rem",
      lineHeight: "120%",
    },
    desktopH2: {
      fontFamily: "Gordita",
      fontWeight: "500",
      lineHeight: "120%",
      fontSize: "2rem",
    },
    desktopH3: {
      fontFamily: "Gordita",
      fontWeight: "500",
      fontSize: "1.75rem",
      lineHeight: "130%",
    },
    desktopH4: {
      fontFamily: "Gordita",
      fontWeight: "500",
      fontSize: "1.5rem",
      lineHeight: "130%",
      letterSpacing: "-0.02em",
    },
    desktopH5: {
      fontFamily: "Gordita",
      fontWeight: "500",
      fontSize: "1.25rem",
      lineHeight: "140%",
      letterSpacing: "-0.015em",
    },
    desktopH6: {
      fontFamily: "Gordita",
      fontWeight: "500",
      fontSize: "1rem",
      lineHeight: "1.375rem",
      letterSpacing: "-0.015em",
    },
    desktopBody1: {
      fontFamily: "Gordita",
      fontWeight: "400",
      fontSize: "1rem",
      lineHeight: "1.625rem",
      letterSpacing: "-0.01em",
    },
    desktopBody2: {
      fontFamily: "Gordita",
      fontWeight: "400",
      fontSize: "0.875rem",
      lineHeight: "1.375rem",
    },
    desktopSubtitle1: {
      fontFamily: "Gordita",
      fontWeight: "500",
      fontSize: "1rem",
      lineHeight: "160%",
      letterSpacing: "-0.01em",
    },
    desktopSubtitle2: {
      fontFamily: "Gordita",
      fontWeight: "500",
      fontSize: "0.875rem",
      lineHeight: "160%",
      letterSpacing: "-0.01em",
    },
    desktopOverline: {
      fontFamily: "Gordita",
      fontWeight: "400",
      fontSize: "0.75rem",
      textTransform: "uppercase",
    },
    desktopCaption: {
      fontFamily: "Gordita",
      fontWeight: "400",
      fontSize: "0.75rem",
      lineHeight: "160%",
    },
    desktopSecondaryDisplay: {
      fontFamily: "Ivy Journal",
      fontWeight: "600",
      fontSize: "3rem",
    },
    desktopSecondaryH1: {
      fontFamily: "Ivy Journal",
      fontWeight: "600",
      fontSize: "2.5rem",
    },
    desktopSecondaryH2: {
      fontFamily: "Ivy Journal",
      fontWeight: "600",
      fontSize: "2rem",
    },
    desktopSecondaryH3: {
      fontFamily: "Ivy Journal",
      fontWeight: "600",
      fontSize: "1.75rem",
      lineHeight: "130%",
    },
    mobileDisplay: {
      fontFamily: "Gordita",
      fontWeight: "500",
      fontSize: "2.125rem",
    },
    mobileH1: {
      fontFamily: "Gordita",
      fontWeight: "500",
      fontSize: "1.75rem",
      lineHeight: "120%",
      letterSpacing: "-0.03em",
    },
    mobileH2: {
      fontFamily: "Gordita",
      fontWeight: "500",
      fontSize: "1.375rem",
      lineHeight: "1.625rem",
      letterSpacing: "-0.03em",
    },
    mobileH3: {
      fontFamily: "Gordita",
      fontWeight: "500",
      fontSize: "1.25rem",
      lineHeight: "130%",
      letterSpacing: "-0.03em",
    },
    mobileH4: {
      fontFamily: "Gordita",
      fontWeight: "500",
      fontSize: "1.125rem",
      lineHeight: "130%",
      letterSpacing: "-0.015em",
    },
    mobileSecondaryDisplay: {
      fontFamily: "Ivy Journal",
      fontWeight: "600",
      fontSize: "2.125rem",
    },
    mobileSecondaryH1: {
      fontFamily: "Ivy Journal",
      fontWeight: "600",
      fontSize: "1.75rem",
    },
    mobileSecondaryH2: {
      fontFamily: "Ivy Journal",
      fontWeight: "600",
      fontSize: "1.375rem",
    },
    mobileSecondaryH3: {
      fontFamily: "Ivy Journal",
      fontWeight: "600",
      fontSize: "1.125rem",
    },
  },
  palette: {
    info: {
      main: "#0EB9B3",
      dark: "#019B96",
      light: "#03F4EC",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#D32F37",
      dark: "#B52129",
      light: "#EF5058",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#ED6C02",
      dark: "#CE7328",
      light: "#FF7300",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#2E7D32",
      dark: "#1B5E20",
      light: "#4CAF50",
      contrastText: "#FFFFFF",
    },
    background: {
      paper: "#F7F6F5",
      default: "#F9F5F2",
      white: "#FFFFFF",
    },

    primary: {
      main: "#343131",
      dark: "#262626",
      light: "#5E5B5B",
      contrastText: "#FFFFFF",
      shades: {
        "4p": "rgba(52, 41, 41, 0.04)",
        "8p": "rgba(52, 41, 41, 0.08)",
        "12p": "rgba(52, 41, 41, 0.12)",
        "30p": "rgba(52, 41, 41, 0.3)",
        "50p": "rgba(52, 41, 41, 0.5);",
      },
    },
    secondary: {
      contrastText: "#342929",
      light: "#FFFFFF",
      dark: "#CCCCCC",
      main: "#FFFFFF",
    },
    grey: {
      "500": "#C6D4BF",
    },
    common: {
      red: {
        main: "#B52129",
      },
      orange: {
        main: "#F68C1E",
        dark: "#CE7328",
      },
      purple: {
        main: "#442647",
      },
      beije: {
        primary: {
          red: {
            main: "#B52129",
          },
          darkOrange: {
            main: "#CE7328",
            light: "#FAE9D4",
          },
          darkGreen: {
            main: "#005743",
          },
          orange: {
            main: "#F68C1E",
            light: "#F4DCBC",
          },
          seaGreen: {
            main: "#0EB9B3",
            light: "#D2E7E0",
          },
          darkPurple: {
            main: "#442647",
          },
          beige: {
            main: "#F9F5ED",
          },
        },
        secondary: {
          brick: {
            main: "#93261B",
          },
          red: {
            main: "#EF4E25",
          },
          orange: {
            main: "#F38054",
            light: "#F5D9C3",
          },
          green: {
            main: "#008D4A",
            light: "#C6D4BF",
          },
          lime: {
            main: "#B9D54D",
            light: "#ECF1CF",
          },
          yellow: {
            main: "#FFC906",
            light: "#FBEEC7",
          },
        },
        tertiary: {
          brown: {
            main: "#610D00",
          },
          black: {
            main: "#342929",
          },
          yellow: {
            main: "#FFE000",
          },
        },
      },
    },
    text: {
      primary: "rgba(0, 0, 0, 0.9)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    divider: "rgba(0, 0, 0, 0.12)",
  },
});

type BeijeTheme = typeof theme;
export type { BeijeTheme };
