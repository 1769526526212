import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useGetProductsQuery,
  useGetProductSubTypesQuery,
} from "src/store/apis";
import type { PacketItem } from "src/types/Packet";
import { combineNamesByLocale } from "src/utils";
import { generatePacketNameByProductItems } from "src/utils/packet";

export function usePacketName() {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const {
    data: subTypes,
    isLoading: isSubTypesLoading,
    isSuccess: isSubTypesSuccess,
  } = useGetProductSubTypesQuery();

  const {
    data: products,
    isLoading: isProductsLoading,
    isSuccess: isProductsSuccess,
  } = useGetProductsQuery();

  useEffect(() => {
    if (!isProductsLoading && !isSubTypesLoading) {
      setLoading(false);
    }
  }, [isProductsLoading, isSubTypesLoading]);

  const getPacketName = useCallback(
    (packetItems: PacketItem[]) => {
      if (!isProductsSuccess || !isSubTypesSuccess) {
        return "";
      }

      const result = combineNamesByLocale(
        generatePacketNameByProductItems(
          packetItems,
          subTypes,
          products,
          i18n.language as "tr" | "en"
        ),
        i18n.language
      );

      return result;
    },
    [i18n.language, isProductsSuccess, isSubTypesSuccess, products, subTypes]
  );

  return { getPacketName, isLoading: loading };
}
