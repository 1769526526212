import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RefundProductItem } from "src/types/Refund";

import type { RootState } from "../store";

type RefundStore = {
  items: RefundProductItem[];
  orderId: string;
  refundedTotalPrice?: number;
  successful?: {
    items: RefundProductItem[];
    refundedTotalPrice: number;
  };
};

const initialState: RefundStore = {
  items: [],
  orderId: "",
};

export const refundProductsSelector = (state: RootState) =>
  state.RefundSlice.items;

export const RefundSlice = createSlice({
  name: "Refund",
  initialState,
  reducers: {
    setSelectedOrderId: (state, { payload }: PayloadAction<string>) => {
      state.orderId = payload;
    },
    addRefundProduct: (
      state,
      { payload }: PayloadAction<RefundProductItem>
    ) => {
      const findIndex = state.items.findIndex((x) => x._id === payload._id);
      if (findIndex > -1) {
        state.items[findIndex] = payload;
        return;
      }

      state.items.push(payload);
    },
    removeRefundItemFromStore: (
      state,
      { payload: refundProductId }: PayloadAction<string>
    ) => {
      const find = state.items.find((x) => x._id === refundProductId);

      if (find) {
        return {
          ...state,
          items: state.items.filter((x) => x._id !== refundProductId),
        };
      }
    },
    setRefundedTotalPrice: (state, { payload }: PayloadAction<number>) => {
      state.refundedTotalPrice = payload;
    },
    setSuccessfulRefund: (
      state,
      { payload }: PayloadAction<RefundStore["successful"]>
    ) => {
      state.successful = payload;
    },
  },
});

export const {
  setSelectedOrderId,
  addRefundProduct,
  removeRefundItemFromStore,
  setRefundedTotalPrice,
  setSuccessfulRefund,
} = RefundSlice.actions;
