import { LinearProgress } from "@mui/material";
import { Theme, styled } from "@mui/material/styles";
import { PropsWithChildren } from "react";

import { useNavigationLoading } from "./hook";

const ProgressBar = styled(LinearProgress)(({ theme }: { theme: Theme }) => ({
  width: "100vw",
  zIndex: theme.zIndex.appBar + 1000,
  top: 0,
  height: theme.spacing(0.5),
  position: "fixed",
}));

export const NavigationLoading = (props: PropsWithChildren<unknown>) => {
  const [loading] = useNavigationLoading();

  return (
    <>
      {loading && <ProgressBar variant="indeterminate" />}
      {props.children}
    </>
  );
};
