import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const selectProduct: (
  products: IProduct[],
  productId: string
) => IProduct = createSelector(
  [(products, _productId) => products, (_state, productId) => productId],
  (products: IProduct[], productId) =>
    products?.filter((product) => product._id === productId)[0]
);

export const getSingleProduct: (
  products: IProduct[],
  packet: IPacketItem
) => IProduct = createSelector(
  [(products) => products, (_, packet) => packet],
  (products: IProduct[], packet) =>
    products?.filter((p) => p._id === packet._product)[0]
);

export const getSubType: (
  subTypes: IProductSubType[],
  subTypeId: string
) => string = createSelector(
  [(subTypes) => subTypes, (_, subTypeId) => subTypeId],
  (subTypes, subTypeId) =>
    subTypes?.find((subType: FixMyType) => subType.id === subTypeId)?.name
);

export const ProductsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
});
