import { BaseAPI } from "./config";

export const Auth = BaseAPI.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    login: builder.mutation<IAccount, ILoginCreditentials>({
      query: (body: ILoginCreditentials) => ({
        url: "/public/user/auth/login",
        method: "post",
        body,
      }),
    }),
    signUp: builder.mutation<IAccount, ISignUpCredentials>({
      query: (body: ISignUpCredentials) => ({
        url: "/public/user/auth/register",
        method: "post",
        body,
      }),
    }),
    forgotPassword: builder.mutation<IForgotPasswordCredentials, unknown>({
      query: (body: IForgotPasswordCredentials) => ({
        url: "/public/user/auth/reset-password",
        method: "post",
        body,
      }),
    }),
    resetPassword: builder.mutation<IResetPasswordCreditentials, unknown>({
      query: (body: IResetPasswordCreditentials) => ({
        url: "/public/user/auth/do-reset-password",
        method: "post",
        body,
      }),
    }),
    checkResetPasswordToken: builder.mutation<
      ICheckResetPasswordCredentials,
      unknown
    >({
      query: (body: ICheckResetPasswordCredentials) => ({
        url: "/public/user/auth/is-valid-reset-password",
        method: "post",
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useCheckResetPasswordTokenMutation,
} = Auth;
