import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ConfirmationState = {
  isOpen: boolean;
  data: {
    title: string;
    message: string;
    confirmLabel?: string;
    onConfirm: (closeCb: () => void) => void;
    cancelLabel?: string;
    onCancel?: (closeCb: () => void) => void;
    confirmLoading?: boolean;
    cancelLoading?: boolean;
  };
};

const initialState: ConfirmationState = {
  isOpen: false,
  data: {
    title: "",
    message: "",
    onConfirm: () => {},
    confirmLoading: false,
    cancelLoading: false,
  },
};

export const ConfirmationModalSlice = createSlice({
  name: "confirmationModal",
  initialState,
  reducers: {
    toggleConfirmationOpen: (
      state,
      action: PayloadAction<ConfirmationState["data"]>
    ) => {
      state.isOpen = true;
      state.data = action.payload;
    },
    toggleConfirmationClose: (state) => {
      state.isOpen = false;
      state.data = { title: "", message: "", onConfirm: () => {} };
    },
    toggleConfirmationActionLoading: (
      state,
      action: PayloadAction<{
        key?: "confirmLoading" | "cancelLoading";
        value: boolean;
      }>
    ) => {
      state.data.confirmLoading = action.payload.value;
      state.data.cancelLoading = action.payload.value;
    },
  },
});

export const {
  toggleConfirmationOpen,
  toggleConfirmationClose,
  toggleConfirmationActionLoading,
} = ConfirmationModalSlice.actions;
