import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// eslint-disable-next-line no-shadow
enum TransactionCheckType {
  "BUY" = "BUY",
  "CREATE" = "CREATE",
}

type TransactionModalType = {
  isOpen: boolean;
  formData: string;
  transactionId: string;
  status: "WAITING" | "OK" | "FAIL" | "";
  transactionCheckType: keyof typeof TransactionCheckType | "";
};

const initialState: TransactionModalType = {
  isOpen: false,
  formData: "",
  transactionId: "",
  status: "",
  transactionCheckType: "",
};

export const TransactionModalSlice = createSlice({
  name: "transactionModal",
  initialState,
  reducers: {
    setTransactionData: (
      state,
      {
        payload,
      }: PayloadAction<{
        key: keyof Pick<
          TransactionModalType,
          "status" | "transactionCheckType"
        >;
        value: FixMyType;
      }>
    ) => {
      const { key, value } = payload;
      state[key] = value;
    },
    open: (state) => {
      state.isOpen = true;
    },
    closeAndClear: (state) => {
      state.isOpen = false;
      state.formData = "";
      state.transactionId = "";
      state.status = "";
      state.transactionCheckType = "";
    },
    setTransactionResponse: (
      state,
      {
        payload,
      }: PayloadAction<{
        formData: TransactionModalType["formData"];
        transactionId: TransactionModalType["transactionId"];
      }>
    ) => {
      const { formData, transactionId } = payload;
      state.formData = atob(formData);
      state.transactionId = transactionId;
    },
  },
});

export const {
  open,
  closeAndClear,
  setTransactionResponse,
  setTransactionData,
} = TransactionModalSlice.actions;
