import { BaseAPI } from "./config";

export const CommensAPI = BaseAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getComments: builder.query<IComment[], void>({
      query: () => "/public/user/reviews?query={}",
      transformResponse: (res: IComments) => res.documents,
    }),
    subscribeNewsletter: builder.mutation({
      query: (data: { email: string }) => ({
        url: "/public/user/newsletter/subscribe",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useGetCommentsQuery, useSubscribeNewsletterMutation } =
  CommensAPI;
