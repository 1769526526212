import { SVGProps, memo } from "react";

const SvgMail04 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    {...props}
  >
    <path
      d="m13.744 2.634 7.528 4.893c.266.173.399.26.495.374a1 1 0 0 1 .189.348c.044.143.044.302.044.62V16.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C19.72 21 18.88 21 17.2 21H6.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C2 18.72 2 17.88 2 16.2V8.868c0-.317 0-.476.044-.62a1 1 0 0 1 .189-.347c.096-.115.229-.201.495-.374l7.528-4.893m3.488 0c-.631-.41-.947-.616-1.287-.696a2 2 0 0 0-.914 0c-.34.08-.656.285-1.287.696m3.488 0 7.224 4.695c.344.224.516.336.576.477a.5.5 0 0 1 0 .388c-.06.141-.232.253-.576.477l-7.224 4.695c-.631.41-.947.616-1.287.696-.3.07-.613.07-.914 0-.34-.08-.656-.285-1.287-.696L3.032 8.671c-.344-.224-.516-.336-.576-.477a.5.5 0 0 1 0-.388c.06-.141.232-.253.576-.477l7.224-4.695"
      stroke="#343131"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgMail04);
export default Memo;
