import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PartialBy } from "src/types/Utilities";

type ISnackBar = {
  open: boolean;
  msg: string;
  action?: boolean;
  variant: "error" | "default";
};

const initialState: ISnackBar = {
  open: false,
  msg: "",
  action: false,
  variant: "default",
};

export const SnackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackbar: (
      state,
      {
        payload,
      }: PayloadAction<PartialBy<ISnackBar, "open" | "variant" | "action">>
    ) => {
      const { msg, action, variant } = payload;
      state.open = true;
      state.msg = msg;
      state.action = action ?? state.action;
      state.variant = variant ?? state.variant;
    },
    close: (state) => {
      state.msg = "";
      state.open = false;
      state.action = false;
      state.variant = "default";
    },
  },
});

export const { showSnackbar, close } = SnackbarSlice.actions;
