import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IAccount = {
  account: null,
  token: null,
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateToken: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
    },
    upsertCredentials: (state, action: PayloadAction<IAccount>) => {
      const { account, token } = action.payload;
      state.account = account;
      state.token = token;
    },
    updateUserBalance: (state, { payload }: PayloadAction<number>) => {
      if (!state.account) return;

      state.account.balance.amount = payload;
    },
    logout: (state) => {
      state.account = null;
      state.token = null;
    },
  },
});

export const { logout, upsertCredentials, updateUserBalance, updateToken } =
  AuthSlice.actions;
