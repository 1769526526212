import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import Router from "next/router";
import { i18n } from "next-i18next";

import { BACKEND_API_BASE_URL } from "src/constant";
import type { RootState } from "src/store";
import { updateToken } from "src/store/slices";
import { verifyToken } from "src/utils";

export const BaseAPI = createApi({
  reducerPath: "BaseAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_API_BASE_URL,
    prepareHeaders(headers) {
      headers.set("Content-Type", "application/json");
      headers.set("language", i18n?.language || "tr");
      headers.set("region-id", "5fa703e35db5b776ac915536");
      return headers;
    },
  }),
  tagTypes: ["Products", "ProductTypes", "ProductSubTypes", "ProductsOfType"],
  endpoints: () => ({}),
});

const baseQuery = fetchBaseQuery({
  baseUrl: BACKEND_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).AuthSlice;
    headers.set("Content-Type", "application/json");
    headers.set("language", i18n?.language || "tr");
    headers.set("region-id", "5fa703e35db5b776ac915536");
    if (token) headers.set("authorization", token);
    return headers;
  },
  async fetchFn(requestInfo, init) {
    return fetch(requestInfo, init).then((response) => {
      const isLoggedIn =
        typeof requestInfo === "object" &&
        !!requestInfo.headers.get("authorization");
      const isAuthenticationNotAccepted = response.status === 401;
      const isAlreadyOnLogoutPage = Router.asPath.includes("/auth/logout");
      const isExceptionalRoute =
        Router.asPath.includes("/auth/login") ||
        Router.asPath.includes("/auth/register") ||
        Router.asPath.includes("/account/change-password");

      if (
        isLoggedIn &&
        isAuthenticationNotAccepted &&
        !isAlreadyOnLogoutPage &&
        !isExceptionalRoute
      ) {
        Router.push(`/auth/logout?redirect=${Router.asPath}`);
        throw new Error("Authentication not accepted");
      }

      return response;
    });
  },
});

const baseQueryWithUpdateTokenMechanism: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  try {
    const token = (result.data as any)?.token;
    const isValidToken = await verifyToken(token);
    if (isValidToken) {
      api.dispatch(updateToken(token));
    }
  } catch (error) {}

  return result;
};

export const BaseAuthorizedAPI = createApi({
  reducerPath: "BaseAuthorizedAPI",
  baseQuery: baseQueryWithUpdateTokenMechanism,
  tagTypes: [
    "Account",
    "Address",
    "Cards",
    "checkout",
    "Orders",
    "Subscription",
    "DuePayment",
  ],
  endpoints: () => ({}),
});

// ! INFO: this config relative to local next api and in future we will remove it.
export const BaseNextAPI = createApi({
  reducerPath: "BaseNextAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api",
  }),
  tagTypes: ["Order", "Account"],
  endpoints: () => ({}),
});
